import * as locales from '@mui/material/locale';
import * as gridlocales from '@mui/x-data-grid-pro/locales';
import { Localization as CoreLocalization } from '@mui/material/locale';
import { Localization, getGridLocalization } from '@mui/x-data-grid/utils/getGridLocalization';
import { GridLocaleText } from '@mui/x-data-grid-pro';

export const parseRouteLngToLocale : (lng: string) => Localization = (lng) => {
    switch (lng) {
        case "en":
            return getGridLocalization(gridlocales.enUS.components.MuiDataGrid.defaultProps.localeText, locales.enUS);
        case "fr":
            return getGridLocalization(gridlocales.frFR.components.MuiDataGrid.defaultProps.localeText, locales.frFR);
        case "es":
            return getGridLocalization(gridlocales.esES.components.MuiDataGrid.defaultProps.localeText, locales.esES);
        //Catalan doesn't exist for datagridlocales defaulting to Spanish:
        case "ca":
            return getGridLocalization(gridlocales.esES.components.MuiDataGrid.defaultProps.localeText, locales.caES);
        case "de":
            return getGridLocalization(gridlocales.deDE.components.MuiDataGrid.defaultProps.localeText, locales.deDE);
        case "zh":
            return getGridLocalization(gridlocales.zhCN.components.MuiDataGrid.defaultProps.localeText, locales.zhCN);
        case "cz":
            return getGridLocalization(gridlocales.csCZ.components.MuiDataGrid.defaultProps.localeText, locales.csCZ);
        case "hu":
            return getGridLocalization(gridlocales.huHU.components.MuiDataGrid.defaultProps.localeText, locales.huHU);
        case "jp":
            return getGridLocalization(gridlocales.jaJP.components.MuiDataGrid.defaultProps.localeText, locales.jaJP);
        case "ko":
            return getGridLocalization(gridlocales.koKR.components.MuiDataGrid.defaultProps.localeText, locales.koKR);
        case "lt":
            return getGridLocalization(ltLTGridLocale, ltLT);
        case "nl":
            return getGridLocalization(gridlocales.nlNL.components.MuiDataGrid.defaultProps.localeText, locales.nlNL);
        case "pt":
            return getGridLocalization(gridlocales.ptPT.components.MuiDataGrid.defaultProps.localeText, locales.ptPT);
        case "ru":
            return getGridLocalization(gridlocales.ruRU.components.MuiDataGrid.defaultProps.localeText, locales.ruRU);
        case "sv":
            return getGridLocalization(gridlocales.svSE.components.MuiDataGrid.defaultProps.localeText, locales.svSE);
        case "sk":
            return getGridLocalization(gridlocales.skSK.components.MuiDataGrid.defaultProps.localeText, locales.skSK);
        //DataGridLocales doesn't exist for this language default to enUS:
        case "in":
            return getGridLocalization(gridlocales.enUS.components.MuiDataGrid.defaultProps.localeText, locales.hiIN);
        //DataGridLocales doesn't exist for this language default to enUS:
        case "id":
            return getGridLocalization(gridlocales.enUS.components.MuiDataGrid.defaultProps.localeText, locales.idID);
        case "it":
            return getGridLocalization(gridlocales.itIT.components.MuiDataGrid.defaultProps.localeText, locales.itIT);
        case "ro":
            return getGridLocalization(gridlocales.roRO.components.MuiDataGrid.defaultProps.localeText, locales.roRO);
        //DataGridLocales doesn't exist for this language default to enUS:
        case "th":
            return getGridLocalization(gridlocales.enUS.components.MuiDataGrid.defaultProps.localeText, locales.thTH);
        //DataGridLocales doesn't exist for this language default to enUS:
        case "my":
            return getGridLocalization(gridlocales.enUS.components.MuiDataGrid.defaultProps.localeText, locales.myMY);
        case "vi":
            return getGridLocalization(gridlocales.viVN.components.MuiDataGrid.defaultProps.localeText, locales.viVN);
        default:
            return getGridLocalization(gridlocales.enUS.components.MuiDataGrid.defaultProps.localeText, locales.enUS);
    }
}

const ltLT : CoreLocalization= {
    components: {
        MuiBreadcrumbs: {
            defaultProps: {
                expandText: 'Rodyti kelią',
            }
        },
        MuiTablePagination: {
            defaultProps: {
                getItemAriaLabel: (type) => {
                    if (type === 'first') {
                        return 'Eiti į pirmą';
                    }
                    if (type === 'last') {
                        return 'Eiti į paskutinį';
                    }
                    if (type === 'next') {
                        return 'Eiti į kitą';
                    }
                    // if (type === 'previous') {
                    return 'Eiti į praeitą';
                },
                labelRowsPerPage: 'Eilučių per psl.:',
                labelDisplayedRows: ({ from, to, count }) =>
                    `${from}-${to} iš ${count !== -1 ? count : `daugiau nei ${to}`}`,
            }
        },
        MuiRating: {
            defaultProps: {
                getLabelText: value => `${value} žvaigdžių}`,
                emptyLabelText: 'Tuščia',
            }
        },
        MuiAutocomplete: {
            defaultProps: {
                clearText: 'Išvalyti',
                closeText: 'Uždaryti',
                loadingText: 'Kraunama…',
                noOptionsText: 'Nėra pasirinkimų',
                openText: 'Atverti',
            }
        },
        MuiAlert: {
            defaultProps: {
                closeText: 'Uždaryti',
            }
        },
        MuiPagination: {
            defaultProps: {
                'aria-label': 'Puslapių navigacija',
                getItemAriaLabel: (type, page, selected) => {
                    if (type === 'page') {
                        return `${selected ? '' : 'Eiti į '}puslapį ${page}`;
                    }
                    if (type === 'first') {
                        return 'Eiti į pirmą';
                    }
                    if (type === 'last') {
                        return 'Eiti į paskutinį';
                    }
                    if (type === 'next') {
                        return 'Eiti į kitą';
                    }
                    // if (type === 'previous') {
                    return 'Eiti į praeitą';
                },
            }
        },
    },
};

const ltLTGridLocale: GridLocaleText = {
    // Root
    noRowsLabel: 'Nėra eilučių',
    noResultsOverlayLabel: 'Rezultatų nerasta.',
  
    // Density selector toolbar button text
    toolbarDensity: 'Tankis',
    toolbarDensityLabel: 'Tankis',
    toolbarDensityCompact: 'Kompaktiškas',
    toolbarDensityStandard: 'Standartinis',
    toolbarDensityComfortable: 'Patogus',
  
    // Columns selector toolbar button text
    toolbarColumns: 'Stulpeliai',
    toolbarColumnsLabel: 'Pasirinkti stulpelius',
  
    // Filters toolbar button text
    toolbarFilters: 'Filtrai',
    toolbarFiltersLabel: 'Rodyti filtrus',
    toolbarFiltersTooltipHide: 'Slėpti filtrus',
    toolbarFiltersTooltipShow: 'Rodyti filtrus',
    toolbarFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} aktyvūs filtrai` : `${count} aktyvus filtras`,
  
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Ieškoti…',
    toolbarQuickFilterLabel: 'Ieškoti',
    toolbarQuickFilterDeleteIconLabel: 'Išvalyti',
  
    // Export selector toolbar button text
    toolbarExport: 'Eksportuoti',
    toolbarExportLabel: 'Eksportuoti',
    toolbarExportCSV: 'Atsisiųsti kaip CSV',
    toolbarExportPrint: 'Spausdinti',
    toolbarExportExcel: 'Atsisiųsti kaip Excel',
  
    // Columns management text
    columnsManagementSearchTitle: 'Ieškoti',
    columnsManagementNoColumns: 'Nėra stulpelių',
    columnsManagementShowHideAllText: 'Rodyti/Slėpti visus',
    columnsManagementReset: 'Atstatyti',
    columnsManagementDeleteIconLabel: 'Išvalyti',
  
    // Filter panel text
    filterPanelAddFilter: 'Pridėti filtrą',
    filterPanelRemoveAll: 'Pašalinti visus',
    filterPanelDeleteIconLabel: 'Ištrinti',
    filterPanelLogicOperator: 'Loginis operatorius',
    filterPanelOperator: 'Operatorius',
    filterPanelOperatorAnd: 'Ir',
    filterPanelOperatorOr: 'Arba',
    filterPanelColumns: 'Stulpeliai',
    filterPanelInputLabel: 'Reikšmė',
    filterPanelInputPlaceholder: 'Filtruoti reikšmę',
  
    // Filter operators text
    filterOperatorContains: 'turi',
    filterOperatorDoesNotContain: 'neturi',
    filterOperatorEquals: 'lygu',
    filterOperatorDoesNotEqual: 'nelygu',
    filterOperatorStartsWith: 'prasideda nuo',
    filterOperatorEndsWith: 'baigiasi su',
    filterOperatorIs: 'yra',
    filterOperatorNot: 'nėra',
    filterOperatorAfter: 'po',
    filterOperatorOnOrAfter: 'po arba tuo metu',
    filterOperatorBefore: 'prieš',
    filterOperatorOnOrBefore: 'prieš arba tuo metu',
    filterOperatorIsEmpty: 'yra tuščias',
    filterOperatorIsNotEmpty: 'nėra tuščias',
    filterOperatorIsAnyOf: 'bet kuris iš',
    'filterOperator=': '=',
    'filterOperator!=': '!=',
    'filterOperator>': '>',
    'filterOperator>=': '>=',
    'filterOperator<': '<',
    'filterOperator<=': '<=',
  
    // Header filter operators text
    headerFilterOperatorContains: 'Turi',
    headerFilterOperatorDoesNotContain: 'Neturi',
    headerFilterOperatorEquals: 'Lygu',
    headerFilterOperatorDoesNotEqual: 'Nelygu',
    headerFilterOperatorStartsWith: 'Prasideda nuo',
    headerFilterOperatorEndsWith: 'Baigiasi su',
    headerFilterOperatorIs: 'Yra',
    headerFilterOperatorNot: 'Nėra',
    headerFilterOperatorAfter: 'Po',
    headerFilterOperatorOnOrAfter: 'Po arba tuo metu',
    headerFilterOperatorBefore: 'Prieš',
    headerFilterOperatorOnOrBefore: 'Prieš arba tuo metu',
    headerFilterOperatorIsEmpty: 'Yra tuščias',
    headerFilterOperatorIsNotEmpty: 'Nėra tuščias',
    headerFilterOperatorIsAnyOf: 'Bet kuris iš',
    'headerFilterOperator=': 'Lygu',
    'headerFilterOperator!=': 'Nelygu',
    'headerFilterOperator>': 'Daugiau nei',
    'headerFilterOperator>=': 'Daugiau arba lygu',
    'headerFilterOperator<': 'Mažiau nei',
    'headerFilterOperator<=': 'Mažiau arba lygu',
  
    // Filter values text
    filterValueAny: 'bet kuris',
    filterValueTrue: 'taip',
    filterValueFalse: 'ne',
  
    // Column menu text
    columnMenuLabel: 'Meniu',
    columnMenuShowColumns: 'Rodyti stulpelius',
    columnMenuManageColumns: 'Tvarkyti stulpelius',
    columnMenuFilter: 'Filtras',
    columnMenuHideColumn: 'Slėpti stulpelį',
    columnMenuUnsort: 'Atšaukti rūšiavimą',
    columnMenuSortAsc: 'Rūšiuoti pagal ASC',
    columnMenuSortDesc: 'Rūšiuoti pagal DESC',
  
    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} aktyvūs filtrai` : `${count} aktyvus filtras`,
    columnHeaderFiltersLabel: 'Rodyti filtrus',
    columnHeaderSortIconLabel: 'Rūšiuoti',
  
    // Rows selected footer text
    footerRowSelected: (count) =>
      count !== 1
        ? `${count.toLocaleString()} pasirinktų eilučių`
        : `${count.toLocaleString()} pasirinkta eilutė`,
  
    // Total row amount footer text
    footerTotalRows: 'Eilučių suma:',
  
    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
      `${visibleCount.toLocaleString()} iš ${totalCount.toLocaleString()}`,
  
    // Checkbox selection text
    checkboxSelectionHeaderName: 'Pasirinkimas su žymimaisiais laukeliais',
    checkboxSelectionSelectAllRows: 'Pasirinkti visas eilutes',
    checkboxSelectionUnselectAllRows: 'Atžymėti visas eilutes',
    checkboxSelectionSelectRow: 'Pasirinkti eilutę',
    checkboxSelectionUnselectRow: 'Atžymėti eilutę',
  
    // Boolean cell text
    booleanCellTrueLabel: 'taip',
    booleanCellFalseLabel: 'ne',
  
    // Actions cell more text
    actionsCellMore: 'daugiau',
  
    // Column pinning text
    pinToLeft: 'Prisegti į kairę',
    pinToRight: 'Prisegti į dešinę',
    unpin: 'Atsegti',
  
    // Tree Data
    treeDataGroupingHeaderName: 'Grupuoti',
    treeDataExpand: 'rodyti vaikus',
    treeDataCollapse: 'slėpti vaikus',
  
    // Grouping columns
    groupingColumnHeaderName: 'Grupuoti',
    groupColumn: (name) => `Grupuoti pagal ${name}`,
    unGroupColumn: (name) => `Nustoti grupuoti pagal ${name}`,
  
    // Master/detail
    detailPanelToggle: 'Detalios informacijos skydelio perjungimas',
    expandDetailPanel: 'Išskleisti',
    collapseDetailPanel: 'Suskleisti',
  
    // Used core components translation keys
    MuiTablePagination: {},
  
    // Row reordering text
    rowReorderingHeaderName: 'Eilučių pertvarkymas',
  
    // Aggregation
    aggregationMenuItemHeader: 'Agregavimas',
    aggregationFunctionLabelSum: 'suma',
    aggregationFunctionLabelAvg: 'vidurkis',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'maks',
    aggregationFunctionLabelSize: 'dydis',
};
