import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { usePdfStyles } from './PdfStyles';
import Watermark from './Watermark';

const LocalStyles = StyleSheet.create({
    frontPage: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: "142px 28px 28px 65px"
    },
    category: {
        fontSize: 6.5,
    },
    categoryContent: {
        fontSize: 10,
    },
    addressText: {
        fontSize: 7.5,
        lineHeight: 1.6
    }
});

export const CoverPdf = (props) => {
    const { data, countryList, pageSize, logo, isProjectPneumaticsOnly, inquiry } = props;

    const { t } = useTranslation();
    var PdfStyles = usePdfStyles();

    const getCountry = (countryId) => {
        if (countryId != undefined && countryId.length > 0) {
            return countryList.find(x => x.countryCode == countryId)?.countryName ?? "";
        }
        else {
            return "";
        }
    }

    const getTitle = () => {
        if (isProjectPneumaticsOnly === true)
            return t("LeakageDetectionAndDocumentation");
        else
            return t("CompressedAirEnergyEfficiencyPreAudit");
    }

    return (
        <Page size={pageSize} style={[LocalStyles.frontPage, PdfStyles.regular]}>
            {inquiry === false && <Watermark projectData={data} />}
            <View style={{ position: "absolute", top: "142px", right: "28px", width: 115, height: 115 }}>
                {logo && <Image style={{ objectFit: "contain", maxWidth: 115, maxHeight: 115 }} src={logo} />}
            </View>
            <View style={{ position: "absolute", top: "142px", left: "65px" }} >
                <View style={{ fontSize: 10, lineHeight: 1.2 }} >
                    <Text >{data.companyName}</Text>
                    <Text >{data.contactPerson}</Text>
                    <Text >{data.address}</Text>
                    <Text >{data.city}</Text>
                    <Text >{data.country}</Text>
                </View>
                <View style={{ height: "75px" }} ></View>
                <Text style={[{ fontSize: 16, maxWidth: 400 }, PdfStyles.bold]}>
                    {getTitle()}
                </Text>
                <Text style={{ fontSize: 12 }}>{t("TuvCertified")}</Text>
            </View>
            {/*//200px to give space for all logo sizes*/}
            <View style={{ position: "absolute", top: "200px", right: "28px", width: 94 }}>
                <View style={{ height: "70px" }} ></View>
                <Text style={LocalStyles.category}>{t("Date")}</Text>
                <Text style={LocalStyles.categoryContent}>{new Date().toLocaleDateString()}</Text>
                <View style={{ height: "30px" }} ></View>
                <Text style={LocalStyles.category}>{t("OurReference")}</Text>
                <Text style={LocalStyles.categoryContent}>{data.country + data.id.toString().padStart(8, '0')}</Text>
                <View style={{ height: "30px" }} ></View>
                <View style={{ height: "30px" }} ></View>
                <View>
                    <Text style={[LocalStyles.addressText, PdfStyles.bold]}>{data.projectSettings?.subsidiaryFestoName}</Text>
                    <Text style={LocalStyles.addressText}>{data.projectSettings?.subsidiaryAddress}</Text>
                    <Text style={LocalStyles.addressText}>{data.projectSettings?.subsidiaryCity}</Text>
                    <Text style={LocalStyles.addressText}>{getCountry(data.projectSettings?.subsidiaryCountry)}</Text>
                    <Text style={LocalStyles.addressText}>{data.projectSettings.subsidiaryContactPerson}</Text>
                    <Text style={LocalStyles.addressText}>{t("Phone") + " " + data.projectSettings.subsidiaryTelephone}</Text>
                    <Text style={LocalStyles.addressText}>{data.projectSettings.subsidiaryEmail}</Text>
                </View>
            </View>
            <View style={{ position: "absolute", bottom: "100px", left: "64px", width: 200, fontSize: 7.5 }}>
                <Text>{t("DataToThirdParties")}</Text>
            </View>
        </Page>
    )
}