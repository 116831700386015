import React from "react"
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, Tooltip } from '@mui/material';
import { StyledTooltip } from '../MaterialUIStyles/Styles';
import "./Autocomplete.css";

export default function AutocompleteComponent(props) {
    const { model, onInputChange, disabled, options } = props;
    let id = props.id ? props.id : model.idkey;

    return (
        <Autocomplete
            freeSolo
            options={options}
            id={"input" + id + 'Autocomplete'}
            onInputChange={!disabled && onInputChange}
            disabled={disabled}
            className={"auto-complete"}
            inputValue={model.value ? model.value.toString() : ""}
            defaultValue={model.value ? model.value.toString() : ""}
            renderInput={(params) => (<Tooltip title={model.isValid ? "" : model.validationError} arrow classes={StyledTooltip} placement="bottom-end">
                <TextField
                    variant="standard"
                    {...params}
                    required={model.validators?.length > 0}
                    error={!model.isValid}
                    label={model.label}
                    fullWidth
                />
            </Tooltip>)}
        />
    );
}