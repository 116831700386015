import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CostSavingsTable from './CostSavingTable/CostSavingTable'
import SavingsBarChartComponent from './SavingsBarChart/SavingsBarChart'
import { Grid, Typography, TextareaAutosize } from '@mui/material';
import { useParams } from "react-router-dom";
import { SavingsBarChartModelFactory } from './Models/SavingsBarChartModel'
import { HeadingComponent, ButtonComponent } from '../Reusable/';
import { API } from '../../helper/ApiHelper';
import {
    savingsEliminatingTheLeakagesTable,
    savingsShuttingOffMainAirSupplyAtNonProductionTimeTable,
    savingsReducingThePressureDropTable,
    savingsReducingThePressureLevelOfTheSystemTable,
    savingsOptimizingTheAirConsumptionAtSingleApplicationsTable,
    cumulativeHighestSavingsPerYearTable
} from './Constants/Constants';
import ImageViewer from '../ImageViewer/ImageViewer';
import * as ImageBlobConstants from '../ImageViewer/Constants';
import { CostSavingsModel } from './CostSavingsModel';
import * as Mapper from '../../helper/Mappers';
import { DirtyStore } from '../../store';
import { OpenGlobalSnackbar } from '../../helper/GlobalVariables';

const CostSavings = (props) => {
    const { denyEdit } = props;

    const csUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "cost-savings";
    const { country, id, lng } = useParams();
    const { t } = useTranslation();
    const [mainModel, setMainModel] = useState(CostSavingsModel);
    const [costSavingsTables, setData] = useState([]);
    const [chartBarModel, setChartBarModel] = useState(SavingsBarChartModelFactory(null))
    const tables = [savingsEliminatingTheLeakagesTable, savingsShuttingOffMainAirSupplyAtNonProductionTimeTable, savingsReducingThePressureDropTable, savingsReducingThePressureLevelOfTheSystemTable, savingsOptimizingTheAirConsumptionAtSingleApplicationsTable, cumulativeHighestSavingsPerYearTable]

    useEffect(() => {
        API.get(csUrl + "/" + id).then((resp) => {
            setData(resp.costSavings);
            setChartBarModel(SavingsBarChartModelFactory(resp.costSavings, t));
            setMainModel(Mapper.mapDataToModelValues(resp, CostSavingsModel));
        });
    }, id)

    const clickCancel = () => {
        history.push("/" + country + "/" + lng + '/projectsandrequests');
    }

    const saveForm = () => {
        let postModel = Mapper.extractValuesFromModel(mainModel);
        API.put(csUrl + '/' + id, postModel).then(() => {
            DirtyStore.setIsDirty(false);
            OpenGlobalSnackbar("success", t('SaveSuccessful'));
        })
    }


    return (
        <div className="container-fluid ruler table-layout-fix">
            <Grid container direction="column" spacing={3} className="cost-savings">
                {tables.map((table, index) => {
                    return costSavingsTables.some(x => x.costSavingType == table) ?
                        <React.Fragment>
                            <Grid item md={12} xs={12} id={"#" + table}>
                                <HeadingComponent value={t(table)} size="h6" />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <CostSavingsTable data={costSavingsTables} costSavingType={table} index={index} hidePressureLevel={table == savingsReducingThePressureDropTable || table == savingsReducingThePressureLevelOfTheSystemTable} />
                            </Grid>
                        </React.Fragment>
                        : null
                })}
                <Grid item xs={12}>
                    <Typography>
                        * {t("InclusinveElectricityMaintenanceInvestmentCosts")}
                    </Typography>
                </Grid>
                {costSavingsTables.length > 0 &&
                    (<React.Fragment>
                        <Grid item md={12} xs={12}>
                            <HeadingComponent value={t('CostSavingsChart')} size="h6" />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <SavingsBarChartComponent data={chartBarModel} />
                        </Grid>
                    </React.Fragment>
                    )
                }

                <Grid item>
                    <HeadingComponent value={t('Recommendations')} size="h6" />
                </Grid>
                <Grid item>
                    <TextareaAutosize
                        disabled={denyEdit}
                        id="textarea_CS-comment"
                        type="text"
                        minRows="5"
                        value={mainModel.costSavingsComment.value}
                        onChange={e => Mapper.updateModel("costSavingsComment", e.target.value, mainModel, setMainModel)}
                    />
                </Grid>
                <Grid item>
                    <ImageViewer disabled={denyEdit} groupId={ImageBlobConstants.CostSavings} />
                </Grid>
                <Grid item>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item><ButtonComponent id="CS-cancel" value={t('Cancel')} color="secondary" onChange={clickCancel}></ButtonComponent></Grid>
                        {!denyEdit && <Grid item><ButtonComponent id="CS-save" value={t('Save')} color="primary" onChange={saveForm}></ButtonComponent></Grid>}
                    </Grid>
                </Grid>
            </Grid >
        </div>
    )
}

export default CostSavings;