import { Translate } from "../Types/Types";

export interface csvLeakage {
    id: number;
    detectedDate?: string;
    detectedBy?: string;
    leackageNumber?: number;
    measurementId?: number;
    leakageRepaired: boolean;
    repairedBy?: string;
    repairComment?: string;
    repairDate?: Date;
    leakageRepairTimeRepairedInput?: number;
    building?: string;
    department?: string;
    machine?: string;
    leakageInputDevice?: string;
    leakageInputValue?: number;
    leakageFlowConverted?: number;
    leakageAction?: string;
    leakageClasification?: string;
    leakageRepairTimeEstimated?: string; //ILeakageRepairTimeEstimated;
    leakageRepearableProdTime: boolean;
    leakageOptimization: boolean;
    leakageComment?: string;
    guid?: string;
    no: number;
}

export interface csvReplacement {
    id: number;
    guid?: string;
    partNumber?: string;
    typeCode?: string;
    component?: string; //IDropdown
    manufacturer?: string;
    replaceWithDifferent: boolean;
    newPartNumber?: string;
    newTypeCode?: string;
    newManufacturer?: string;
    quantity: string;
    orderSparePart: boolean;
}

export type csvColumn = ({
    key: string;
    label: string;
    example?: string,
    alternateMatches?: string[];
    fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: 'input' | 'checkbox' | 'select',
    },
    validations?: [
        {
            // Can be "required" / "unique" / "regex"
            rule: 'required' | 'unique' | 'regex',
            errorMessage: string,
            // There can be "info" / "warning" / "error" levels. Optional. Default "error".
            level: 'info' | 'warning' | 'error',
        },
    ],
} | null)[]

export const rsiTheme = {
    colors: {
        background: 'white',
        subtitleColor: "#0091dc",
        rsi: {
            50: "#0091dc",
            100: "#0091dc",
            200: "#0091dc",
            300: "#0091dc",
            400: "#0091dc",
            500: "#0091dc",
            600: "#0091dc",
            700: "#0091dc",
            800: "#0091dc",
            900: "#0091dc",
        },
    },
}

export const rsiTranslations = (t: Translate) => ({
    uploadStep: {
        title: t("RSI_UploadStep_Title"),
        manifestTitle: t("RSI_UploadStep_ManifestTitle"),
        manifestDescription: t("RSI_UploadStep_ManifestDescription"),
        maxRecordsExceeded: (maxRecords: string) => t("RSI_UploadStep_MaxRecordsExceeded").replace("${0}", maxRecords),
        dropzone: {
            title: t("RSI_UploadStep_Dropzone_Title"),
            errorToastDescription: t("RSI_UploadStep_Dropzone_ErrorToastDescription"),
            activeDropzoneTitle: t("RSI_UploadStep_Dropzone_ActiveDropzoneTitle"),
            buttonTitle: t("RSI_UploadStep_Dropzone_ButtonTitle"),
            loadingTitle: t("RSI_UploadStep_Dropzone_LoadingTitle"),
        },
        selectSheet: {
            title: t("RSI_SelectSheet_Title"),
            nextButtonTitle: t("Next"), 
            backButtonTitle: t("Back"), 
        },
    },
    selectHeaderStep: {
        title: t("RSI_SelectHeaderStep_Title"),
        nextButtonTitle: t("Next"), 
        backButtonTitle: t("Back"), 
    },
    matchColumnsStep: {
        title: t("RSI_MatchColumnsStep_Title"),
        nextButtonTitle: t("Next"), 
        backButtonTitle: t("Back"), 
        userTableTitle: t("RSI_MatchColumnsStep_UserTableTitle"),
        templateTitle: t("RSI_MatchColumnsStep_TemplateTitle"),
        selectPlaceholder: t("RSI_MatchColumnsStep_SelectPlaceholder"),
        ignoredColumnText: t("RSI_MatchColumnsStep_IgnoredColumnText"),
        subSelectPlaceholder: t("RSI_MatchColumnsStep_SubSelectPlaceholder"),
        matchDropdownTitle: t("RSI_MatchColumnsStep_MatchDropdownTitle"),
        unmatched: t("RSI_MatchColumnsStep_Unmatched"),
        duplicateColumnWarningTitle: t("RSI_MatchColumnsStep_DuplicateColumnWarningTitle"),
        duplicateColumnWarningDescription: t("RSI_MatchColumnsStep_DuplicateColumnWarningDescription"),
    },
    validationStep: {
        title: t("RSI_ValidationStep_Title"),
        nextButtonTitle: t("RSI_ValidationStep_NextButtonTitle"),
        backButtonTitle: t("Back"), 
        noRowsMessage: t("RSI_ValidationStep_NoRowsMessage"),
        noRowsMessageWhenFiltered: t("RSI_ValidationStep_NoRowsMessageWhenFiltered"),
        discardButtonTitle: t("RSI_ValidationStep_DiscardButtonTitle"),
        filterSwitchTitle: t("RSI_ValidationStep_FilterSwitchTitle"),
    },
    alerts: {
        confirmClose: {
            headerTitle: t("RSI_Alerts_ConfirmClose_HeaderTitle"),
            bodyText: t("RSI_Alerts_ConfirmClose_BodyText"),
            cancelButtonTitle: t("Cancel"), 
            exitButtonTitle: t("RSI_Alerts_ConfirmClose_ExitButtonTitle"),
        },
        submitIncomplete: {
            headerTitle: t("RSI_Alerts_SubmitIncomplete_HeaderTitle"),
            bodyText: t("RSI_Alerts_SubmitIncomplete_BodyText"),
            bodyTextSubmitForbidden: t("RSI_Alerts_SubmitIncomplete_BodyTextSubmitForbidden"),
            cancelButtonTitle: t("Cancel"), 
            finishButtonTitle: t("RSI_Alerts_SubmitIncomplete_FinishButtonTitle"),
        },
        submitError: {
            title: t("Error"),
            defaultMessage: t("RSI_Alerts_SubmitError_DefaultMessage"),
        },
        unmatchedRequiredFields: {
            headerTitle: t("RSI_Alerts_UnmatchedRequiredFields_HeaderTitle"),
            bodyText: t("RSI_Alerts_UnmatchedRequiredFields_BodyText"),
            listTitle: t("RSI_Alerts_UnmatchedRequiredFields_ListTitle"),
            cancelButtonTitle: t("Cancel"),
            continueButtonTitle: t("RSI_Alerts_UnmatchedRequiredFields_ContinueButtonTitle"),
        },
        toast: {
            error: t("Error"), 
        },
    },
});
