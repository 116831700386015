import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Button, Typography, IconButton, Popper, ClickAwayListener, Tooltip } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Person, ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { authContext, logOut } from "../../adalConfig";
import "./UserDialog.css"

export default function UserDialog(props) {
    const { user } = props;

    let { country, lng } = useParams();
    const { t } = useTranslation();
    let history = useHistory();

    const [anchorEl2, setAnchorEl2] = useState(null);
    const open = Boolean(anchorEl2);
    const id = open ? 'simple-popper' : undefined;

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    let logout = () => {
        logOut(window.location.origin + "/" + country + "/" + lng);
    };

    let login = () => {
        history.push("/" + country + "/" + lng + "/projectsandrequests");
        authContext.login();
    };

    return (
        <div>
            {user ?
                <React.Fragment>
                    <Tooltip title="Open user dialog">
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            className="User"
                            aria-owns="mouse-over-popover"
                            onClick={handleClick2}
                            id="btn_header-user"
                            startIcon={<Person style={{ color: "#0091dc" }} />}
                            endIcon={anchorEl2 == null ?
                                <ArrowDropDown
                                    style={{ color: "#0000008a" }}
                                />
                                :
                                <ArrowDropUp
                                    style={{ color: "#0000008a" }}
                                />
                            }
                            style={{ marginLeft: "24px" }}
                        >
                            {user.firstName} {user.lastName}
                        </Button>
                    </Tooltip>
                    <Popper id={id} open={open} anchorEl={anchorEl2} className="popper">
                        <ClickAwayListener onClickAway={handleClose2}>
                            <Grid container className="user-panel" direction="column" >
                                <Grid>
                                    <Typography className="user-panel-welcome"> <span style={{ fontWeight: "bold" }}>Welcome</span>, {user.firstName} {user.lastName}</Typography>
                                </Grid>
                                <Grid container className="panel_aligment" direction="column" >
                                    <Grid>
                                        <Button
                                            id="btn_header-projectsAndRequests"
                                            className="dialog_button"
                                            onClick={() => { history.push("/" + country + "/" + lng + "/projectsandrequests"); handleClose2() }}
                                        >
                                            {t('ProjectsAndRequests')}
                                        </Button>
                                    </Grid>
                                    <Grid>
                                        <Button
                                            id="btn_header-countrySettings"
                                            className="dialog_button"
                                            onClick={() => { history.push("/" + country + "/" + lng + "/SettingsPanel/ExecutorManagement"); handleClose2() }}
                                        >
                                            {t('ServiceProviderSettings')}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid className="panel_aligment">
                                    <Button id="btn_header-Logout" onClick={() => logout()} className="dialog_button">
                                        {t("Logout")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ClickAwayListener>
                    </Popper>
                </React.Fragment>
                :
                <IconButton id="btn_header-user-empty" onClick={() => login()} size="large" sx={{ borderRadius: "4px", padding: "6px", marginRight: "8px" }}>
                    <Person style={{ color: "black" }} />
                    <Typography><span style={{ color: "black" }}>{t("Login")}</span></Typography>
                </IconButton>
            }
        </div>
    );
}
