import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from "react-router-dom";
import ImageViewer from '../ImageViewer/ImageViewer';
import * as Mapper from '../../helper/Mappers';
import { TextFieldComponent, HeadingComponent, CheckboxComponent, ButtonComponent, DatePickerComponent, SingleSelectComponent, AutocompleteDropdown, NumberInputFieldComponent, PhoneNumberInputField } from '../Reusable'
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Paper, TextareaAutosize, FormHelperText } from '@mui/material';
import { API } from '../../helper/ApiHelper';
import { GeneralInformationModelFactory, applyRounding } from './GeneralInformationModel';
import * as Fmt from '../../helper/Formatters';
import * as InputRestrictions from '../../helper/InputRestrictions';
import * as ImageBlobConstants from '../ImageViewer/Constants';
import { OpenGlobalSnackbar, MaintenanceFrequencySelects, Salutations } from '../../helper/GlobalVariables';
import { CountryListStore, DirtyStore } from '../../store';
import { useIsMount } from '../../helper/UseIsMount';
import ImportFullDialog from '../ImportDryerCompressorFull/ImportFullDialog';
import * as Consts from '../../helper/ConstantRepository';
import { validateModel } from '../../helper/Validator';
import { IMaskInput } from 'react-imask';
import './GeneralInformation.css';

export default function GeneralInformation(props) {
    const { denyEdit } = props;
    const giUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "general-information";

    const { t } = useTranslation();
    const isMount = useIsMount();
    let { country, lng, id } = useParams();
    const location = useLocation();
    let history = useHistory();
    const [genInfModel, setGenInfModel] = useState(GeneralInformationModelFactory(t));
    const [countryList, setCountryList] = useState([]);
    const [isUtilisationValid, setIsUtilisationValid] = useState(true);
    const [importOpen, setImportOpen] = useState(false);
    const [previousCO2Value, setPreviousCO2Value] = useState(0);


    useEffect(() => {
        Mapper.updateExistingModelFormattingLabels(genInfModel, GeneralInformationModelFactory(t), setGenInfModel);
    }, [t]);

    useEffect(() => {
        if (!isMount)
            CountryListStore.initCountryList(lng);
    }, [lng]);

    useEffect(() => {
        CountryListStore.initCountryList(lng);
        let countryListSub = CountryListStore.countryList.subscribe(list => {
            setCountryList(list);
        })

        if (id) {
            loadExistingData();
        }

        return () => countryListSub.unsubscribe();
    }, [id]);

    const clickCancel = () => {
        history.push("/" + country + "/" + lng + '/projectsandrequests');
    }

    const loadExistingData = () => {
        API.get(giUrl + '/' + id).then(resp => {
            let mappedModel = Mapper.mapDataToModelValues(resp.project, GeneralInformationModelFactory(t));
            mappedModel.cO2GridAverage.value = resp.cO2GridAverage;

            let calcMappedModel = Mapper.mapMachingDataToModelValues(resp.giCalcResult, GeneralInformationModelFactory(t));
            if (location.hash.includes('validate'))
                mappedModel = validateModel(mappedModel);
            setGenInfModel({ ...mappedModel, ...applyRounding(calcMappedModel, country) });
        })
    }
    const postForm = () => {
        let postModel = {
            projectId: id,
            project: Mapper.extractValuesFromModel(genInfModel),
            cO2GridAverage: genInfModel.cO2GridAverage.value
        }
        API.put(giUrl + '/' + id, postModel).then(() => {
            OpenGlobalSnackbar("success", t('SaveSuccessful'));
            loadExistingData();
            DirtyStore.setIsDirty(false);
        })
    }

    const saveForm = () => {
        let isValid = utilisationValidation(genInfModel)[0];
        checkProcessHeatValidity();
        let allValid = validateFormModel() === true && isValid === true;
        if (allValid === true)
            postForm();
        else
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
        return allValid;
    }

    const validateFormModel = () => {
        let isValid = true;
        Object.values(genInfModel).forEach(m => {
            if (m.validators.length > 0)
                m.validators.forEach(v => {
                    if (v(m.value, genInfModel) === false) {
                        isValid = false;
                        m.isValid = false;
                    }
                });
        });
        setGenInfModel({ ...genInfModel });
        return isValid;
    }

    const updateModel = (property, value, setDirty = true) => {
        if (denyEdit) return;
        if (genInfModel[property].value === value) return;
        let updatedModel = Mapper.updateModel(property, value, genInfModel);
        if (updatedModel.pneumaticTools.isValid === true && updatedModel.pneumaticMachinery.isValid === true && updatedModel.process.isValid === true) {
            let validatedModel = utilisationValidation(updatedModel)[1];
            updatedModel = validatedModel;
        }
        setGenInfModel({ ...updatedModel });
        checkProcessHeatValidity(updatedModel);
        if (setDirty)
            DirtyStore.setIsDirty(saveForm);
    }

    const updateSelectModel = (element, value, values) => {
        genInfModel[element].value = (value == 0) ? null : values.find(x => x.id == value);
        genInfModel[element].isValid = true;
        genInfModel[element].validators.map((v) => { if (v(genInfModel[element].value) === false) genInfModel[element].isValid = false; });
        setGenInfModel({ ...genInfModel });
        DirtyStore.setIsDirty(saveForm);
    }

    const updateC02Info = (cO2GridAverage, greenEnergy) => {
        setPreviousCO2Value(genInfModel['cO2GridAverage'].value);
        genInfModel['cO2GridAverage'].value = cO2GridAverage;
        genInfModel['greenEnergy'].value = greenEnergy;
        setGenInfModel({ ...genInfModel });
        DirtyStore.setIsDirty(saveForm);
    }

    const isProcessHeatSelectedValidator = (model) => {
        let isValid = false;

        if (model.heatRecoverySystem.value === true) {
            if (model.heating.value === true || model.hotWaterGeneration.value === true)
                isValid = true;
        }
        else
            isValid = true;
        return isValid;
    }

    const checkProcessHeatValidity = (model = genInfModel) => {
        let isValid = isProcessHeatSelectedValidator(model);
        model.heatRecoverySystem.isValid = isValid;
        if (model.heatRecoverySystem.value === false) {
            model.heating.value = false;
            model.hotWaterGeneration.value = false;
        }
        setGenInfModel({ ...model });
    }

    const utilisationValidation = (model) => {
        let isValid = true;
        if (Number.parseFloat(model.pneumaticTools.value) + Number.parseFloat(model.pneumaticMachinery.value) + Number.parseFloat(model.process.value) !== 100)
            isValid = false;
        setIsUtilisationValid(isValid);
        return [isValid, model];
    }

    const MemoizedTextFieldComponent = React.useMemo(() => {
        const country = countryList.find(x => x.countryCode === genInfModel.country.value);
        updateModel("postcodeRegex", country?.countryPostalRegex, false);
        return (
            <TextFieldComponent
                t={t}
                model={genInfModel.postcode}
                disabled={denyEdit || genInfModel.country?.value?.length <= 0 || genInfModel.country?.value === undefined}
                InputLabelProps={{ shrink: genInfModel.country?.value?.length > 0 && genInfModel.country?.value !== undefined }}
                InputProps={{
                    inputComponent: IMaskInput,
                    inputProps: {
                        mask: Fmt.CountryToPostalMask(country),
                        unmask: 'typed',
                        lazy: (genInfModel.country?.value?.length <= 0 || genInfModel.country?.value === undefined) || !genInfModel.postcodeRegex?.value,
                        placeholderChar: '#',
                        definitions: {
                            '#': /[0-9]/,
                            '@': /[a-zA-Z]/,
                        },
                        onAccept: (e) => {
                            updateModel("postcode", e);
                        }
                    },
                }}
            />
        );
    }, [countryList, genInfModel.postcode.value, genInfModel.country.value]);

    return (
        <div className="general-information">
            <ImportFullDialog
                open={importOpen}
                setOpen={setImportOpen}
                viewList={[Consts.ProjectImportCompressor, Consts.ProjectImportDryer]}
                generalInformation
                reloadData={() => window.location.reload()}
            />
            <Grid container spacing={5} direction="row">
                {!denyEdit &&
                    <Grid item xs={12}>
                        <ButtonComponent id="GI-import" value={t("ImportCustomerData")} color="primary" onChange={() => setImportOpen(true)}></ButtonComponent>
                    </Grid>
                }
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3} direction="column">
                        <Grid item id="#GeneralInformation">
                            <HeadingComponent value={t('GeneralInformation')} size="h6" />
                        </Grid>
                        <Grid item>
                            <TextFieldComponent disabled={denyEdit} t={t} model={genInfModel.customer} onChange={e => {
                                updateModel("customer", e.target.value);
                            }} />
                        </Grid>
                        <Grid item>
                            <TextFieldComponent disabled={denyEdit} t={t} model={genInfModel.companyName} onChange={e => {
                                updateModel("companyName", e.target.value)
                            }} />
                        </Grid>
                        <Grid item>
                            <TextFieldComponent disabled={denyEdit} t={t} model={genInfModel.address} onChange={e => {
                                updateModel("address", e.target.value)
                            }} />
                        </Grid>
                        <Grid item>
                            <AutocompleteDropdown
                                disabled={denyEdit}
                                model={genInfModel.country}
                                label={t('Country')}
                                options={countryList}
                                optionName="countryName"
                                optionKey="countryCode"
                                onChange={e => {
                                    updateModel("country", e?.countryCode);
                                    updateModel("postcode", "");
                                }} />
                        </Grid>
                        <Grid item>
                            {MemoizedTextFieldComponent}
                        </Grid>
                        <Grid item>
                            <TextFieldComponent disabled={denyEdit} t={t} model={genInfModel.city} onChange={e => {
                                updateModel("city", e.target.value)
                            }} />
                        </Grid>
                        <Grid item>
                            <SingleSelectComponent
                                t={t}
                                model={genInfModel.salutation}
                                listFromDb={Salutations}
                                onChange={(e) => updateModel('salutation', e.target.value)}
                                variant={'standard'} />
                        </Grid>
                        <Grid item>
                            <TextFieldComponent disabled={denyEdit} t={t} model={genInfModel.firstName} onChange={e => {
                                updateModel("firstName", e.target.value)
                            }} />
                        </Grid>
                        <Grid item>
                            <TextFieldComponent disabled={denyEdit} t={t} model={genInfModel.lastName} onChange={e => {
                                updateModel("lastName", e.target.value)
                            }} />
                        </Grid>
                        <Grid item>
                            <PhoneNumberInputField disabled={denyEdit} model={genInfModel.phoneNumber} onChange={e => {
                                updateModel("phoneNumber", e)
                            }} />
                        </Grid>
                        <Grid item>
                            <TextFieldComponent disabled={denyEdit} t={t} model={genInfModel.email} onChange={e => {
                                updateModel("email", e.target.value)
                            }} />
                        </Grid>
                        <Grid item>
                            <TextFieldComponent disabled={denyEdit} t={t} model={genInfModel.numberOfEmployeesInThePlant} onChange={e => {
                                updateModel("numberOfEmployeesInThePlant", e.target.value)
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={4} direction="column" id="GI-work-time">
                        <Grid item>
                            <HeadingComponent value={t('WorkingTimeInHours')} size="h6" />
                        </Grid>
                        <Grid item>
                            <TableContainer component={Paper} >
                                <Table aria-label="spanning table">
                                    <TableBody>
                                        <TableRow className="table-body-row" key={1}>
                                            <TableCell align="right">
                                                <TextFieldComponent disabled={denyEdit} t={t} model={genInfModel.mondayToFridayWorkingTime} onChange={e => {
                                                    updateModel("mondayToFridayWorkingTime", e.target.value)
                                                }} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextFieldComponent disabled={denyEdit} t={t} model={genInfModel.saturdayWorkingTime} onChange={e => {
                                                    updateModel("saturdayWorkingTime", e.target.value)
                                                }} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextFieldComponent disabled={denyEdit} t={t} model={genInfModel.sundayWorkingTime} onChange={e => {
                                                    updateModel("sundayWorkingTime", e.target.value)
                                                }} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextFieldComponent t={t} disabled model={genInfModel.workingTimeWeek} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextFieldComponent t={t} disabled model={genInfModel.workingTimeYear} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item>
                            <HeadingComponent value={t('AmbientClimaticConditionsHeader')} size="h6" />
                        </Grid>
                        <Grid item>
                            <TableContainer component={Paper} >
                                <Table aria-label="spanning table">
                                    <TableBody>
                                        <TableRow className="table-body-row" key={1}>
                                            <TableCell align="right">
                                                {t('Temperature') + Fmt.getUnitInBracket("temperature", t)}
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumberInputFieldComponent disabled={denyEdit} t={t} model={genInfModel.temperatureSpring} onChange={e => {
                                                    updateModel("temperatureSpring", InputRestrictions.Decimal(e.target.value))
                                                }} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumberInputFieldComponent disabled={denyEdit} t={t} model={genInfModel.temperatureSummer} onChange={e => {
                                                    updateModel("temperatureSummer", InputRestrictions.Decimal(e.target.value))
                                                }} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumberInputFieldComponent disabled={denyEdit} t={t} model={genInfModel.temperatureAutumn} onChange={e => {
                                                    updateModel("temperatureAutumn", InputRestrictions.Decimal(e.target.value))
                                                }} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumberInputFieldComponent disabled={denyEdit} t={t} model={genInfModel.temperatureWinter} onChange={e => {
                                                    updateModel("temperatureWinter", InputRestrictions.Decimal(e.target.value))
                                                }} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextFieldComponent t={t} disabled model={{
                                                    value: Fmt.round(genInfModel.temperatureAverage.value, country, 1),
                                                    label: t("Average"),
                                                    isValid: true
                                                }} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="table-body-row" key={2}>
                                            <TableCell align="right">
                                                {t('Humidity') + Fmt.getPercentage()}
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumberInputFieldComponent disabled={denyEdit} t={t} model={genInfModel.humiditySpring} onChange={e => {
                                                    updateModel("humiditySpring", InputRestrictions.Decimal(e.target.value))
                                                }} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumberInputFieldComponent disabled={denyEdit} t={t} model={genInfModel.humiditySummer} onChange={e => {
                                                    updateModel("humiditySummer", InputRestrictions.Decimal(e.target.value))
                                                }} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumberInputFieldComponent disabled={denyEdit} t={t} model={genInfModel.humidityAutumn} onChange={e => {
                                                    updateModel("humidityAutumn", InputRestrictions.Decimal(e.target.value))
                                                }} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumberInputFieldComponent disabled={denyEdit} t={t} model={genInfModel.humidityWinter} onChange={e => {
                                                    updateModel("humidityWinter", InputRestrictions.Decimal(e.target.value))
                                                }} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextFieldComponent t={t} disabled model={{
                                                    value: Fmt.round(genInfModel.humidityAverage.value, country, 1),
                                                    label: t("Average"),
                                                    isValid: true
                                                }} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={genInfModel.altitudeAboveSeaLevel} onChange={e => {
                                updateModel("altitudeAboveSeaLevel", InputRestrictions.Decimal(e.target.value, country, 2))
                            }} />
                        </Grid>
                        <Grid item>
                            <TextFieldComponent disabled t={t} model={genInfModel.atmosphericPressure} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3} direction="column">
                        <Grid item id="#EnergyCost">
                            <HeadingComponent value={t('EnergyCost')} size="h6" />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={genInfModel.costsPerkWh} onChange={e => {
                                updateModel("costsPerkWh", InputRestrictions.Decimal(e.target.value, 2))
                            }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} direction="column">
                        <Grid item id="#CO2GRA">
                            <HeadingComponent value={t('CO2GRA')} size="h6" />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CheckboxComponent
                                key={Math.random()}
                                model={genInfModel.greenEnergy}
                                onChange={e => { updateC02Info(e.target.checked ? 0 : previousCO2Value, e.target.checked) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <NumberInputFieldComponent
                                disabled={denyEdit}
                                t={t}
                                model={genInfModel.cO2GridAverage}
                                onChange={e => { updateC02Info(InputRestrictions.Decimal(e.target.value, 1), InputRestrictions.Decimal(e.target.value, 1) == "0") }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} id="#PercentegeofCompressedAir">
                    <HeadingComponent value={t('PercentegeofCompressedAir')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={5} direction="row">
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={3} direction="column" >
                                <Grid item xs={12}>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={genInfModel.pneumaticTools} onChange={e => {
                                        updateModel("pneumaticTools", e.target.value)
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={genInfModel.pneumaticMachinery} onChange={e => {
                                        updateModel("pneumaticMachinery", e.target.value)
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={genInfModel.process} onChange={e => {
                                        updateModel("process", e.target.value)
                                    }} />
                                </Grid>
                                {!isUtilisationValid &&
                                    <Grid item xs={12} className="validation-formhelper-error-textfield">
                                        <FormHelperText id="p_validation-PercentegeofCompressedAir" error={true}>
                                            {t("ValidationErrorSumShouldBeOneHundred")}
                                        </FormHelperText>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <CheckboxComponent disabled={denyEdit} model={genInfModel.isMaintanancePlanAvailable} onChange={e => {
                                        updateModel("isMaintanancePlanAvailable", e.target.checked);
                                    }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} id="GI-maintenance-section">
                            <Grid container direction="column" spacing={3}>
                                <Grid item xs={12}>
                                    <DatePickerComponent
                                        disabled={denyEdit}
                                        t={t}
                                        model={genInfModel.lastMaintenance}
                                        onChange={e => updateModel("lastMaintenance", e)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SingleSelectComponent
                                        disabled={denyEdit}
                                        id="lastMaintenanceFrequency"
                                        t={t}
                                        listFromDb={MaintenanceFrequencySelects}
                                        model={genInfModel.lastMaintenanceFrequency}
                                        label={t('LastMaintenanceFrequency')}
                                        onChange={e => { updateSelectModel("lastMaintenanceFrequency", e.target.value, MaintenanceFrequencySelects) }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePickerComponent
                                        disabled={denyEdit}
                                        t={t}
                                        model={genInfModel.lastLeackageDetection}
                                        onChange={e => updateModel("lastLeackageDetection", e)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <HeadingComponent value={t('AirSupplyTime')} size="h6" />
                        </Grid>
                        <Grid item>
                            <CheckboxComponent disabled={denyEdit} model={genInfModel.isTheAirSupllyShuttedOff} onChange={e => {
                                updateModel("isTheAirSupllyShuttedOff", e.target.checked);
                            }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <HeadingComponent value={t('ProcessHeat')} size="h6" />

                        </Grid>
                        <Grid item >
                            <CheckboxComponent disabled={denyEdit} model={genInfModel.heatRecoverySystem} onChange={e => {
                                updateModel("heatRecoverySystem", e.target.checked);
                            }} />

                            {
                                genInfModel.heatRecoverySystem.value &&
                                <React.Fragment>
                                    <Grid item >
                                        <h6>{t('HeatRecoverySystemQuestion')}</h6>
                                        {
                                            !genInfModel.heatRecoverySystem.isValid && <FormHelperText error={true}>At least one processing option must be selected</FormHelperText>
                                        }
                                    </Grid>
                                    <Grid item >
                                        <Grid container direction="column" spacing={1}>
                                            <Grid item >
                                                <CheckboxComponent disabled={denyEdit} model={genInfModel.heating} onChange={e => {
                                                    updateModel("heating", e.target.checked);
                                                }} />
                                            </Grid>
                                            <Grid item >
                                                <CheckboxComponent disabled={denyEdit} model={genInfModel.hotWaterGeneration} onChange={e => {
                                                    updateModel("hotWaterGeneration", e.target.checked);
                                                }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <HeadingComponent value={t('Comment')} size="h6" />
                        </Grid>
                        <Grid item>
                            <TextareaAutosize disabled={denyEdit} id="textarea-GI-comment" type="text" value={genInfModel.generalInformationComment.value} minRows="5"
                                onChange={e => {
                                    updateModel("generalInformationComment", e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <ImageViewer disabled={denyEdit} groupId={ImageBlobConstants.GeneralInfo} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item><ButtonComponent id="GI-cancel" value={t('Cancel')} color="secondary" onChange={clickCancel}></ButtonComponent></Grid>
                    {!denyEdit && <Grid item><ButtonComponent id="GI-save" value={t('Save')} color="primary" onChange={saveForm}></ButtonComponent></Grid>}
                </Grid>
            </Grid>

        </div>
    )
}

