import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
// @ts-expect-error not converted yet
import { usePdfStyles } from './PdfStyles';
import * as ImageBlobConstants from '../../ImageViewer/Constants';
import Watermark from './Watermark';
import { formatImageDimensions } from '../../../helper/Pdf';
import { SplitText } from '../../../helper/PdfTested';
import { decodeImageName } from '../../../helper/Formatters';
import { IPDFPageProps } from '../../Types/Types';

export const ExecutiveSummaryPdf = (props: IPDFPageProps) => {
    const { data } = props;
    const { t } = useTranslation();
    const PdfStyles = usePdfStyles();

    const getImagesByGroup = (groupName: string) => {
        let newArray: any[] = [];
        if (props.newImageArray != undefined && props.newImageArray.length > 0)
            newArray = props.newImageArray.filter(image => image.groupId === groupName);
        newArray.forEach(image => formatImageDimensions(image))
        return newArray;
    }

    const generateImageComponent = (groupName: string) => {
        const images = getImagesByGroup(groupName);
        return (
            <View>
                {images != undefined && images.length > 0 && <Text style={PdfStyles.text}>{t('Images')}:</Text>}
                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} >
                    {images.map((image : any)=>
                        <View
                            key={image.groupId + image.name}
                            wrap={false}
                            style={{
                                marginRight: 10,
                                width: image.width < 150 ? image.width + 10 : image.width,
                                minWidth: 100,
                                maxHeight: image.height + 30
                            }}
                        >
                            <Text style={PdfStyles.spacer}></Text>
                            <Text style={PdfStyles.text}>{image.width < 150 ? SplitText(decodeImageName(image.name), 20) : decodeImageName(image.name)}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                            <Image src={image.url} style={{ width: image.width, height: image.height }} />
                        </View>
                    )}
                </View>
                {images.length > 0 && <Text style={PdfStyles.spacer}></Text>}
            </View>
        );
    }

    return (
        <Page size={props.pageSize} style={PdfStyles.page}>
            <Watermark projectData={data} />
            <Text style={PdfStyles.pageNumbers} render={({ pageNumber, totalPages }) => (pageNumber + ' ' + t('of') + ' ' + totalPages)} fixed />
            <View>
                <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["ExecutiveSummary"] = pageNumber; return null; }} />
                <Text style={PdfStyles.boldHeading}>2 {t('ExecutiveSummary')}</Text>
                {props.tocValues.executiveSummarySections.generalInformation != undefined &&
                    <View>
                        <View wrap={true}>
                            <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["GeneralInformation"] = pageNumber; return null; }} />
                            <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.generalInformation} {t('GeneralInformation')}</Text>
                        </View>
                        <Text style={PdfStyles.spacer}></Text>
                        <Text style={PdfStyles.commentText}>{props.data.generalInformationComment}</Text>
                        <Text style={PdfStyles.spacer}></Text>
                        {generateImageComponent(ImageBlobConstants.GeneralInfo)}
                    </View>
                }
                {props.tocValues.executiveSummarySections.compressedAirGeneration != undefined &&
                    <View>
                        <View wrap={true}>
                            <Text render={({ pageNumber }) => { props.tocValues["CompressedAirGeneration"] = pageNumber; return null; }} />
                            <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.compressedAirGeneration}. {t('CompressedAirGeneration')}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                            <Text style={PdfStyles.commentText}>{props.data.compressedAirGeneration?.comment ?? ''}</Text>
                        </View>
                        <Text style={PdfStyles.spacer}></Text>
                        {generateImageComponent(ImageBlobConstants.CompressedAirGen)}
                        {props.data.compressedAirGeneration.compressorList
                            .map((e, i) => {
                                if (props.data.imageList.some(image => image.groupId.includes(e.guid)) || (e.comment != undefined && e.comment.trim() != ""))
                                    return <View key={'compressorComment' + i}>
                                        <View>
                                            <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.compressedAirGeneration}.{i + 1} {t('Compressor')} {i + 1}</Text>
                                            <Text style={PdfStyles.commentText}>{e?.comment ?? ''}</Text>
                                            <Text style={PdfStyles.spacer}></Text>
                                        </View>
                                        {e.guid != undefined && generateImageComponent(ImageBlobConstants.IndividualCompressorPrefix + e.guid)}
                                    </View>
                            }
                            )}
                    </View>
                }
                {props.tocValues.executiveSummarySections.airQuality != undefined &&
                    <View>
                        <View wrap={true}>
                            <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["AirQualitySummary"] = pageNumber; return null; }} />
                            <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.airQuality}. {t('AirQuality')}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                            <Text style={PdfStyles.commentText}>{props.data.airQualityComment}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                        </View>
                        {generateImageComponent(ImageBlobConstants.AirQuality)}
                        {props.tocValues.executiveSummarySections.dryer != undefined &&
                            <View>
                                <View wrap={true}>
                                    <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["Dryers"] = pageNumber; return null; }} />
                                    <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.airQuality}.{props.tocValues.executiveSummarySections.dryer} {t('Dryers')}</Text>
                                </View>
                                <Text style={PdfStyles.spacer}></Text>
                                {props.data.individualDryersList
                                    .map((e, i) => {
                                        if (props.data.imageList.some(image => image.groupId.includes(e.guid)) || (e.individualDryerComment != undefined && e.individualDryerComment.trim() != ""))
                                            return <View key={'individualDryersComment' + i}>
                                                <View>
                                                    <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.airQuality}.{props.tocValues.executiveSummarySections.dryer}.{i + 1} {t('Dryer')} {i + 1}</Text>
                                                    <Text style={PdfStyles.commentText}>{e.individualDryerComment}</Text>
                                                    <Text style={PdfStyles.spacer}></Text>
                                                </View>
                                                {e.guid != undefined && generateImageComponent(ImageBlobConstants.IndividualDryerPrefix + e.guid)}
                                            </View>
                                    }
                                    )}
                            </View>
                        }
                        {props.tocValues.executiveSummarySections.measurement != undefined &&
                            <View>
                                <View wrap={true}>
                                    <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["AirQualityMeasurementSummary"] = pageNumber; return null; }} />
                                    <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.airQuality}.{props.tocValues.executiveSummarySections.measurement} {t('AirQualityMeasurement')}</Text>
                                </View>
                                <Text style={PdfStyles.spacer}></Text>
                                {props.data.airQualityMeasurementList
                                    .map((e, i) => {
                                        if (props.data.imageList.some(image => image.groupId.includes(e.guid)) || (e.airQualityMeasurementComment != undefined && e.airQualityMeasurementComment.trim() != ""))
                                            return <View key={'measurementComment' + i}>
                                                <View>
                                                    <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.airQuality}.{props.tocValues.executiveSummarySections.measurement}.{i + 1} {t('Measurement')} {i + 1}</Text>
                                                    <Text style={PdfStyles.commentText}>{e.airQualityMeasurementComment}</Text>
                                                    <Text style={PdfStyles.spacer}></Text>
                                                </View>
                                                {e.guid != undefined && generateImageComponent(ImageBlobConstants.AirQualityMeasurementPrefix + e.guid)}
                                            </View>
                                    }
                                    )}
                            </View>
                        }
                    </View>
                }
                {props.tocValues.executiveSummarySections.airNetwork != undefined &&
                    <View>
                        <View wrap={true}>
                            <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["AirNetworkSummary"] = pageNumber; return null; }} />
                            <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.airNetwork}. {t('AirNetwork')}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                            <Text style={PdfStyles.commentText}>{props.data.airNetworkAnalysis?.comment ?? ''}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                        </View>
                        {generateImageComponent(ImageBlobConstants.AirNetworkAnalysis)}
                        {props.tocValues.executiveSummarySections.distributionLine != undefined &&
                            <View>
                                <View wrap={true}>
                                    <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["DistributionLine"] = pageNumber; return null; }} />
                                    <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.airNetwork}.{props.tocValues.executiveSummarySections.distributionLine} {t('DistributionLine')}</Text>
                                </View>
                                <Text style={PdfStyles.spacer}></Text>
                                {props.data.airNetworkAnalysis.distributionLineList
                                    .map((e, i) => {
                                        if (props.data.imageList.some(image => image.groupId.includes(e.guid)) || (e.comment != undefined && e.comment.trim() != ""))
                                            return <View key={'distributionLineComment' + i}>
                                                <View>
                                                    <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.airNetwork}.{props.tocValues.executiveSummarySections.distributionLine}.{i + 1} {t('DistributionLine')} {i + 1}</Text>
                                                    <Text style={PdfStyles.commentText}>{e?.comment ?? ''}</Text>
                                                    <Text style={PdfStyles.spacer}></Text>
                                                </View>
                                                {e.guid != undefined && generateImageComponent(ImageBlobConstants.DirstibutionLinePrefix + e.guid)}
                                            </View>
                                    }
                                    )}
                            </View>
                        }
                        {props.tocValues.executiveSummarySections.airReceiver != undefined &&
                            <View>
                                <View wrap={true}>
                                    <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["AirReceiversSummary"] = pageNumber; return null; }} />
                                    <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.airNetwork}.{props.tocValues.executiveSummarySections.airReceiver} {t('AirReceivers')}</Text>
                                </View>
                                <Text style={PdfStyles.spacer}></Text>
                                {props.data.airNetworkAnalysis.airReceiverList
                                    .map((e, i) => {
                                        if (props.data.imageList.some(image => image.groupId.includes(e.guid)) || (e.comment != undefined && e.comment.trim() != ""))
                                            return <View key={'airReceiversComment' + i}>
                                                <View>
                                                    <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.airNetwork}.{props.tocValues.executiveSummarySections.airReceiver}.{i + 1} {t('airReceiverParts')} {i + 1}</Text>
                                                    <Text style={PdfStyles.commentText}>{e?.comment ?? ''}</Text>
                                                    <Text style={PdfStyles.spacer}></Text>
                                                </View>
                                                {e.guid != undefined && generateImageComponent(ImageBlobConstants.AirReceiverPrefix + e.guid)}
                                            </View>
                                    }
                                    )}
                            </View>
                        }
                        {props.tocValues.executiveSummarySections.pressureDrop != undefined &&
                            <View>
                                <View wrap={true}>
                                    <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["PressureDrop"] = pageNumber; return null; }} />
                                    <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.airNetwork}.{props.tocValues.executiveSummarySections.pressureDrop} {t('pressureDropParts')}</Text>
                                </View>
                                <Text style={PdfStyles.spacer}></Text>
                                {props.data.airNetworkAnalysis.pressureDropAnalysisList
                                    .map((e, i) => {
                                        if (props.data.imageList.some(image => image.groupId.includes(e.guid)) || (e.comment != undefined && e.comment.trim() != ""))
                                            return <View key={'pressureDropsComment' + i}>
                                                <View>
                                                    <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.airNetwork}.{props.tocValues.executiveSummarySections.pressureDrop}.{i + 1} {t('pressureDropParts')} {i + 1}</Text>
                                                    <Text style={PdfStyles.commentText}>{e?.comment ?? ''}</Text>
                                                    <Text style={PdfStyles.spacer}></Text>
                                                </View>
                                                {e.guid != undefined && generateImageComponent(ImageBlobConstants.PreassureDropAnalysisPrefix + e.guid)}
                                            </View>
                                    }
                                    )}
                            </View>
                        }
                    </View>
                }
                {props.tocValues.executiveSummarySections.macac != undefined &&
                    <View>
                        <View wrap={true}>
                            <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["MACAC"] = pageNumber; return null; }} />
                            <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.macac}. {t('MA/CACTabName')}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                            <Text style={PdfStyles.commentText}>{props.data.macacComment}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                        </View>
                        {generateImageComponent(ImageBlobConstants.MACAC)}
                        {props.tocValues.executiveSummarySections.application != undefined &&
                            <View>
                                <View wrap={true}>
                                    <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["Applications"] = pageNumber; return null; }} />
                                    <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.macac}.{props.tocValues.executiveSummarySections.application} {t('Applications')}</Text>
                                </View>
                                <Text style={PdfStyles.spacer}></Text>
                                {props.data.projectApplicationList.map((e, i) => {
                                    if (props.data.imageList.some(image => image.groupId.includes(e.guid)) || (e.applicationComment != undefined && e.applicationComment.trim() != ""))
                                        return <View key={'applicationsComment' + i}>
                                            <View>
                                                <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.macac}.{props.tocValues.executiveSummarySections.application}.{i + 1} {t('Application')} {i + 1}</Text>
                                                <Text style={PdfStyles.commentText}>{e.applicationComment}</Text>
                                                <Text style={PdfStyles.spacer}></Text>
                                            </View>
                                            {e.guid != undefined && generateImageComponent(ImageBlobConstants.ApplicationPrefix + e.guid)}
                                        </View>
                                }
                                )}
                            </View>
                        }
                    </View>
                }
                {props.tocValues.executiveSummarySections.leakageAnalysis != undefined &&
                    <View>
                        <View wrap={true}>
                            <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["LeakageAnalysis"] = pageNumber; return null; }} />
                            <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.leakageAnalysis}. {t('LeakageAnalysis')}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                            <Text style={PdfStyles.commentText}>{props.data.leakageAnalysis?.comment ?? ''}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                        </View>
                        {generateImageComponent(ImageBlobConstants.LeakageAnalysis)}
                    </View>
                }
                {props.tocValues.executiveSummarySections.ldar != undefined &&
                    <View>
                        <View wrap={true}>
                            <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["LeakageDetectionAndDocumentation"] = pageNumber; return null; }} />
                            <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.ldar}. {t('LeakageDetectionAndDocumentation')}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                            <Text style={PdfStyles.commentText}>{props.data.projectLDAR?.comment ?? ''}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                        </View>
                        {generateImageComponent(ImageBlobConstants.LDAR)}
                    </View>
                }
                {props.tocValues.executiveSummarySections.costSavings != undefined &&
                    <View>
                        <View wrap={true}>
                            <Text style={PdfStyles.text} render={({ pageNumber }) => { props.tocValues["CostSavingsExec"] = pageNumber; return null; }} />
                            <Text style={PdfStyles.text}>2.{props.tocValues.executiveSummarySections.costSavings}. {t('Recommendations')}</Text>
                            <Text style={PdfStyles.spacer}></Text>
                            <Text style={PdfStyles.commentText}>{props.data.costSavingsComment ?? ''}</Text>
                        </View>
                        {generateImageComponent(ImageBlobConstants.CostSavings)}
                    </View>
                }
            </View>
        </Page>
    );
}