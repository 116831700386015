import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import { FilterModel, TableHeadingContent, TableHeadCells, SimpleCheckbox, ModalComponent } from "../Reusable";
import ReplacementPartDialog from './ReplacementPartDialog';
import { ReplacementPartModel } from './ReplacementPartModel';
import * as Mapper from '../../helper/Mappers';
import { OpenGlobalSnackbar } from '../../helper/GlobalVariables';
import { ProjectStore } from "../../store";
import { Edit } from '@mui/icons-material';
import './ReplacementParts.css';

export default function ReplacementParts(props) {
    const { setIsDirty, replacementPartList, setReplacementPartList } = props;

    const { t } = useTranslation();

    const [modalModel, setModalModel] = useState({ open: false, message: "" });
    const [showDialog, setShowDialog] = useState(false);
    const [filteredData, setFilteredData] = useState([])
    const [replacementIndexForEdit, setReplacementIndexForEdit] = useState(undefined);
    const [isEdit, setIsEdit] = useState(false);
    const [mainModel, setMainModel] = useState([]);
    const [entriesMarkedForDeletion, setEntriesMarkedForDeletion] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);

    const replacementPartModel = ReplacementPartModel(t);
    const denyEdit = !ProjectStore.ProjectRunning.value;

    useEffect(() => {
        if (replacementPartList != undefined && replacementPartList.length > 0) {
            let mappedModel = Mapper.mapArrayDataToArrayModel(replacementPartList, ReplacementPartModel(t));
            setMainModel(mappedModel);

            let listData = replacementPartList.map((row, index) => {
                row.no = index + 1;
                return row;
            });
            setFilteredData(listData);
        }
        else {
            setFilteredData([]);
            setMainModel([]);
        }
    }, [replacementPartList]);

    const isCheckedForDeletion = id => entriesMarkedForDeletion.includes(id);

    const checkForDeletion = id => {
        let isAlreadyChecked = isCheckedForDeletion(id);
        let checkedArray = isAlreadyChecked ? entriesMarkedForDeletion.filter(x => x != id) : [...entriesMarkedForDeletion, id];
        setEntriesMarkedForDeletion(checkedArray);
    }

    const clickDelete = () => {
        if (entriesMarkedForDeletion == undefined || entriesMarkedForDeletion.length == 0)
            OpenGlobalSnackbar("danger", t('NoEntriesCheckedError'));
        else
            clickDeleteOpenModal();
    }

    const clickDeleteOpenModal = () => {
        modalModel.open = true;
        modalModel.okText = t('Yes');
        modalModel.cancelText = t('No');
        modalModel.message = t('DeleteSelectedRowsQuestion');
        modalModel.clickOk = () => deleteTableLine();
        setModalModel({ ...modalModel });
    }

    const deleteTableLine = () => {
        const deletionIndexes = entriesMarkedForDeletion.sort().reverse();
        deletionIndexes.forEach(index => replacementPartList.splice(index, 1));
        setReplacementPartList([...replacementPartList]);
        setEntriesMarkedForDeletion([]);
        setIsDirty();
    }

    const addReplacement = () => {
        setIsEdit(false);
        setReplacementIndexForEdit(undefined);
        setShowDialog(true);
    };

    const editReplacement = (index) => {
        setIsEdit(true);
        setReplacementIndexForEdit(index);
        setShowDialog(true);
    };

    const updateReplacement = (data) => {
        Object.assign(replacementPartList[replacementIndexForEdit], data);
        setReplacementPartList([...replacementPartList]);
        setIsDirty();
    }

    const addNewReplacement = (data) => {
        var newList = [...replacementPartList];
        newList.push(data);
        setReplacementPartList(newList);
        setIsDirty();
    }

    const filterFunction = (tempdata) => {
        setFilteredData(tempdata);
    }

    const updateOrderSparePart = (id, checked) => {
        let part = replacementPartList.find(x => x.id == id);
        part.orderSparePart = checked;
        setReplacementPartList([...replacementPartList]);
    }

    return (
        <div className='replacement-parts-table'>
            <ModalComponent model={modalModel} />
            <ReplacementPartDialog
                disabled={denyEdit}
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                updateReplacement={updateReplacement}
                addNewReplacement={addNewReplacement}
                replacementForEdit={mainModel[replacementIndexForEdit]}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
            />
            <Grid item xs={12}>
                <TableHeadingContent
                    clickDelete={clickDelete}
                    clickAdd={addReplacement}
                    defaultData={filteredData}
                    filterData={replacementPartList}
                    onFilterFunction={filterFunction}
                    filterModel={FilterModel.ReplacmentParts}
                    denyEdit={denyEdit}
                    tableId={"ReplacmentParts"}
                />
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="spanning table">
                        <TableHead>
                            <TableRow>
                                {!denyEdit && <TableCell></TableCell>}
                                <TableHeadCells
                                    model={replacementPartModel}
                                    propertyList={["no", "partNumber", "typeCode", "component", "manufacturer", "quantity", "orderSparePart"]}
                                    tableName="ReplacmentParts"
                                    data={filteredData}
                                    setData={setFilteredData}
                                />
                                <TableCell></TableCell>
                            </TableRow >
                        </TableHead >
                        {filteredData && filteredData.length > 0 &&
                            <TableBody>
                                {filteredData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map((item, index) =>
                                    <TableRow key={"ReplacmentParts" + index}>
                                        {!denyEdit &&
                                            <TableCell>
                                                <SimpleCheckbox
                                                    id={"ReplacmentParts-checkbox" + index}
                                                    key={Math.random()}
                                                    defaultChecked={isCheckedForDeletion(index)}
                                                    color="default"
                                                    onChange={() => checkForDeletion(index)}
                                                />
                                            </TableCell>
                                        }
                                        <TableCell id={"td_ReplacmentParts-no" + index}>{item.no}</TableCell>
                                        <TableCell id={"td_ReplacmentParts-newPartNumber" + index}>{item.replaceWithDifferent ? item.newPartNumber : item.partNumber}</TableCell>
                                        <TableCell id={"td_ReplacmentParts-newTypeCode" + index}>{item.replaceWithDifferent ? item.newTypeCode : item.typeCode}</TableCell>
                                        <TableCell id={"td_ReplacmentParts-component" + index}>{t(item.component?.value)}</TableCell>
                                        <TableCell id={"td_ReplacmentParts-newManufacturer" + index}>{item.replaceWithDifferent ? item.newManufacturer : item.manufacturer}</TableCell>
                                        <TableCell id={"td_ReplacmentParts-quantity" + index}>{item.quantity}</TableCell>
                                        <TableCell id={"td_ReplacmentParts-orderSparePart" + index}>
                                            <SimpleCheckbox
                                                id={"ReplacmentParts-orderSparePart" + index}
                                                key={Math.random()}
                                                defaultChecked={item.orderSparePart}
                                                color="default"
                                                onChange={e => updateOrderSparePart(item.id, e.target.checked)}
                                            />
                                        </TableCell>
                                        <TableCell >
                                            <IconButton
                                                id={"btn_ReplacmentParts-edit" + index}
                                                onClick={() => editReplacement(index)}
                                                size="large"
                                            >
                                                <Edit />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                    component="div"
                    count={mainModel && mainModel.length > 0 ? mainModel.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={(e, page) => setCurrentPage(page)}
                    onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
                />
            </Grid>
        </div>
    );
}