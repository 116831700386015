import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonComponent, HeadingComponent, SpinnerComponent, SwitchComponent } from '../../Reusable';
import { CountryParam, IdParam, ILDARCaclResult, ILeakage, IndLeakageCalcResult } from '../../Types/Types';
import { Dialog, DialogContent, DialogTitle, IconButton, DialogActions, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { Document, pdf } from '@react-pdf/renderer';
import { API } from '../../../helper/ApiHelper';
import { asyncImage, getExecutorLogo } from '../../../helper/Pdf';
import { LeakageActions, LeakageInputDevices, LeakageRepairTimeEstimations, OpenGlobalSnackbar } from '../../../helper/GlobalVariables';
// @ts-expect-error not converted yet
import PrintLeakagesPdf from "./PrintLeakagesPdf"
import * as Fmt from '../../../helper/Formatters'
import * as ImageBlobConstants from '../../ImageViewer/Constants';
import { LeakageCalculationsModelFactory, LeakageModelFactory } from '../Leakage/LeakageModel';
import { DataGridPro, GridColDef, GridRowParams, GridRowSelectionModel, GridRowsProp, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { ChangesRef, datesColDef, getRowClassName, inputColDef, itemsColDef, sxStyle, valueColDef, yesNoColDef } from '../../MassEdit/MassEditHelper';
import { v4 as uuidv4 } from 'uuid';
import Grid from '@mui/material/Unstable_Grid2';

interface IProps {
    data: (ILeakage & IndLeakageCalcResult)[],
    ldarCalcResults: ILDARCaclResult
}

export default function PrintLeakagesButton(props: IProps) {
    const getProjectImagesUrl = process.env.REACT_APP_DEVFESSIMAGESERVICES_BASE + "blob/project/";
    const { data, ldarCalcResults } = props;
    const { t } = useTranslation();
    const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
    const { id, country } = useParams<IdParam & CountryParam>();
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [isGeneratePressed, setIsGeneratePressed] = useState(false);
    const [isLowQuality, setIsLowQuality] = useState(true);
    const highQualityAmount = 50;

    const pdfDocument = (
        leakages: (ILeakage & IndLeakageCalcResult)[],
        dataIMG: (void | { url: string; width: number; height: number; })[],
        logo: string | null) => (
        <Document>
            {leakages.map((item, index) => <PrintLeakagesPdf
                logo={logo}
                item={item}
                dataIMG={dataIMG}
                ldarCalcResults={ldarCalcResults}
                key={index}
                index={index}
                country={country}
            />)}
        </Document>
    );

    const generatePDFDocument = async (leakages: (ILeakage & IndLeakageCalcResult)[]) => {
        setIsGeneratePressed(true);

        const dataIMG = await API.post(`${getProjectImagesUrl}${id}/${isLowQuality}/ldar-images`,
            {
                groupId: ImageBlobConstants.LDAR,
                FileNameList: leakages.map(x => x.guid)
            });

        if (dataIMG == undefined || data == undefined) {
            OpenGlobalSnackbar("danger", t('ErrorGeneratingPdf'));
            setIsGeneratePressed(false);
            return;
        }

        try {
            const logo = await getExecutorLogo();
            const newImageArray = await asyncImage(dataIMG);
            const blobPdf = await pdf(pdfDocument(leakages, newImageArray, logo));
            blobPdf.updateContainer(pdfDocument(leakages, newImageArray, logo));
            const blob = await blobPdf.toBlob();
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", "Leakage_List.pdf");
            document.body.appendChild(link);
            link.click();
            OpenGlobalSnackbar("success", t('PdfGeneratedSuccessfully'));
        } catch (e) {
            OpenGlobalSnackbar("danger", t('ErrorGeneratingPdf'));
        } finally {
            setIsGeneratePressed(false);
        }
    };

    const initiatePdfGeneration = () => {
        const selectedRows = leakageRows.filter((row) =>
            rowSelectionModel.includes(row.gridId)
        );
        if (selectedRows.length > 0)
            generatePDFDocument(selectedRows);
        else
            OpenGlobalSnackbar("danger", t('ErrorNoLeakagesFoundForTheSelection'));
    }

    const leakageModel = { ...LeakageModelFactory(t), ...LeakageCalculationsModelFactory(t) };
    const leakageRows: GridRowsProp<ILeakage & IndLeakageCalcResult & { gridId: string }> = React.useMemo(() => data.filter(x => x.id > 0).map((item) => ({ gridId: uuidv4(), ...item })), [data]);
    const leakageApiRef = useGridApiRef();
    const leakageChangesRef = React.useRef<ChangesRef>({ unsavedRows: {}, rowsBeforeChange: {} });
    const leakageColumns = React.useMemo<GridColDef[]>(() => [
        valueColDef(leakageModel, t, "leackageNumber", { minWidth: 20 }),
        valueColDef(leakageModel, t, "measurementId"),
        datesColDef(leakageModel, t, "detectedDate"),
        inputColDef(leakageModel, t, "detectedBy"),

        inputColDef(leakageModel, t, "building"),
        inputColDef(leakageModel, t, "department"),
        inputColDef(leakageModel, t, "machine"),

        itemsColDef(leakageModel, t, "leakageInputDevice", LeakageInputDevices),
        inputColDef(leakageModel, t, "leakageInputValue"),
        valueColDef(leakageModel, t, "leakageFlowConverted", { valueFormatter: (value: number) => Fmt.round(value, country, 2) }),
        itemsColDef(leakageModel, t, "leakageAction", LeakageActions),
        itemsColDef(leakageModel, t, "leakageRepairTimeEstimated", LeakageRepairTimeEstimations),
        yesNoColDef(leakageModel, t, "leakageOptimization"),
        yesNoColDef(leakageModel, t, "leakageRepearableProdTime"),

        yesNoColDef(leakageModel, t, "leakageRepaired"),
        inputColDef(leakageModel, t, "repairedBy"),
        datesColDef(leakageModel, t, "repairDate"),
        inputColDef(leakageModel, t, "leakageRepairTimeRepairedInput"),
        inputColDef(leakageModel, t, "leakageComment")
    ], [leakageChangesRef, leakageApiRef]);

    return (
        <>
            <>
                <SpinnerComponent open={isGeneratePressed} />
                <Dialog maxWidth='xl' fullWidth={true} open={pdfDialogOpen}>
                    <DialogTitle>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid>
                                <HeadingComponent value={t("PrintLeakages")} size="h6" />
                            </Grid>
                            <Grid>
                                <IconButton color="inherit" onClick={() => setPdfDialogOpen(false)} size="large">
                                    <Close fontSize="small" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={1} direction="column">
                            <Grid xs={12}>
                                <div style={{ height: 500, width: '100%' }}>
                                    <DataGridPro
                                        density='compact'
                                        checkboxSelection
                                        keepNonExistentRowsSelected
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    leakageInputDevice: false,
                                                    leakageInputValue: false,
                                                    leakageFlowConverted: false,
                                                    leakageAction: false,
                                                    leakageRepearableProdTime: false,
                                                },
                                            },
                                        }}
                                        rows={leakageRows}
                                        apiRef={leakageApiRef}
                                        columns={leakageColumns}
                                        autosizeOnMount={true}
                                        isCellEditable={() => false}
                                        autosizeOptions={{ includeHeaders: true }}
                                        getRowId={(row: GridValidRowModel) => row?.gridId}
                                        rowSelectionModel={rowSelectionModel}
                                        onRowSelectionModelChange={(newRowSelectionModel) => {
                                            if (!isLowQuality && (newRowSelectionModel.length > highQualityAmount)) {
                                                setRowSelectionModel(newRowSelectionModel.slice(0, highQualityAmount));
                                            } else {
                                                setRowSelectionModel(newRowSelectionModel);
                                            }
                                        }}
                                        isRowSelectable={(params: GridRowParams) => {
                                            const isCurrentlySelected = rowSelectionModel.includes(params.id);
                                            return isLowQuality ? true : (isCurrentlySelected || rowSelectionModel.length < highQualityAmount);
                                        }}
                                        getRowClassName={({ id }) => getRowClassName(id, leakageChangesRef)}
                                        sx={sxStyle}
                                        slots={{
                                            toolbar: () => (
                                                <GridToolbarContainer>
                                                    <Grid container alignItems="center" justifyContent="space-between" paddingLeft={1}>
                                                        <Grid>
                                                            <GridToolbarColumnsButton />
                                                        </Grid>
                                                        <Grid>
                                                            <GridToolbarFilterButton />
                                                        </Grid>
                                                        <Grid container alignItems="center" spacing={1}>
                                                            <Grid>
                                                                <SwitchComponent
                                                                    checked={isLowQuality}
                                                                    onChange={e => {
                                                                        setRowSelectionModel([])
                                                                        setIsLowQuality(e.target.checked)
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid>
                                                                <Typography>{t("LowQualityImagesExplanation")}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </GridToolbarContainer>
                                            ),
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justifyContent="flex-end">
                            <Grid><ButtonComponent id="PrintLeakagesDialog-cancel" value={t('Cancel')} color="secondary" onChange={() => setPdfDialogOpen(false)}></ButtonComponent></Grid>
                            <Grid><ButtonComponent id="PrintLeakagesDialog-print" value={t('Generate')} color="primary" onChange={initiatePdfGeneration}></ButtonComponent></Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </>
            <ButtonComponent id="LDAR-print-leakages" value={t('PrintLeakages')} color="primary" onChange={() => setPdfDialogOpen(true)} />
        </>
    )
}
