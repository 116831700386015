import React from 'react'
import { useTranslation } from "react-i18next";
import "./Maintenance.css"

export default function Maintenance() {
    const { t } = useTranslation();

    return (
        <div>
            <div className="container">
                <header>
                    <img className="icon_fess" src="/maintenance/pictures/EnergySavingServices_final.svg" alt="" />
                    <a href="https://www.festo.com/">
                        <img className="icon_festo" src="/maintenance/pictures/logo_blue.svg" alt="" />
                    </a>
                </header>
                <div id="showcase_text">
                    <h1>{t("siteIsUnderMaintenance")}</h1>
                    <h2>{t("thankYouForYourUnderstanding")}</h2>
                </div>
                <div id="showcase_image">
                    <div className="shape_box">
                        <div className="align">
                            <img className="shape_support" src="/maintenance/pictures/support.svg" alt="" />
                            <img className="shape_wait" src="/maintenance/pictures/waiting.svg" alt="" />
                            <h4>{t("Approx")}: 10min - 1h</h4>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="left">
                    © 2021 Festo Corporation. All Rights Reserved
                </div>
                <div className="right">
                    <a href="https://www.festo.com/us/en/e/legal/imprint-id_3741/">Imprint</a>
                    <a href="https://www.festo.com/us/en/e/privacy-statement-id_3749/">Data privacy</a>
                    <a href="https://www.festo.com/us/en/e/privacy-statement-id_3749/">Terms and Conditions of Sale</a>
                    <a href="https://www.festo.com/us/en/e/privacy-statement-id_3749/">Cloud Services</a>
                </div>
            </footer>
        </div>
    )
}
