import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import * as Mapper from '../../helper/Mappers';
import { HeadingComponent, ButtonComponent, LinkComponent } from '../Reusable/'
import { Grid, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Container, TablePagination } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material';
import { API } from '../../helper/ApiHelper';
import { GetConstant } from '../../helper/ConstantRepository';
import { ReplacementPartsCartModel, ReplacementPartsCartHeadings } from './ReplacementPartsCartModel';
import BreadcrumbsComponent from "../Breadcrumbs/Breadcrumbs";
import { SparePartsToExcel } from './Export/SparePartToExcel';
import { OpenGlobalSnackbar } from '../../helper/GlobalVariables';
import { TableHeadingContent, FilterModel, SimpleCheckbox } from "../Reusable";
import { groupBy } from "../../helper/Sorter";
import { saveAs } from 'file-saver';
import { ProjectStore } from '../../store';
import "./ReplacementPartsCart.css"

export default function ReplacementPartsCart(props) {
    const getUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "replacement-part";

    const { t } = useTranslation();
    let { country, lng, id } = useParams();
    let history = useHistory();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [parts, setParts] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [fullResponse, setFullResponse] = useState([]);
    const [projectInfo, setProjectInfo] = useState({});
    const [partsCsvData, setPartsCsvData] = useState([]);

    useEffect(() => {
        if (id) {
            loadExistingData(id);
        }
    }, [id]);

    useEffect(() => {
        props.handleSideBar(true);
        const projSub = ProjectStore.ProjectInfo.subscribe(x => setProjectInfo(x));
        ProjectStore.initProjectInfo(id);
        return () => {
            projSub.unsubscribe();
        }
    }, [])

    const loadExistingData = (id) => {
        API.get(`${getUrl}/cart/${id}`).then(resp => {
            for (const item of resp) {
                //this is needed also for excel so, this also fixes the double/chained translation issue
                item.component = t(item.component);
                item.newComponent = t(item.newComponent);
            }
            let mappedParts = Mapper.mapArrayDataToArrayModel(resp, ReplacementPartsCartModel);
            setFullResponse(resp);
            sortAndSetParts(mappedParts);
            setFilterData(mappedParts);

            const groupedMap = groupBy(resp, x => x.partNumber);
            const groupedArray = Array.from(groupedMap);
            const csvData = [["Quantity", "Part number"]].concat(groupedArray.map(x => [x[1].length, x[0]]));
            setPartsCsvData(csvData);
        })
    }

    const replacementPartsSorter = (x1, x2) => {
        let t1 = t(x1.service.value);
        let t2 = t(x2.service.value);
        if (t1 > t2)
            return 1;
        if (t1 < t2)
            return -1;
        else
            return x1.no.value - x2.no.value;
    }

    const clickCancel = () => {
        history.push("/" + country + "/" + lng + '/project/' + id + "/PS");
    }

    const buySpareParts = () => {
        var sspLink = GetConstant("SspLink");
        var groupedParts = groupReplacementByPartNr();
        let groupedPartKeys = Object.keys(groupedParts);
        if (groupedPartKeys.length == 0) {
            OpenGlobalSnackbar("info", t('ReplacementPartListIsEmpty'));
            return;
        }
        for (const [index, key] of groupedPartKeys.entries()) {
            sspLink += "&m_" + index + "=" + key + "&q_" + index + "=" + groupedParts[key];
        }
        var win = window.open(sspLink, '_blank');
        win.focus();
    }

    const groupReplacementByPartNr = () => {
        const result = {};
        const defaultManufacturer = GetConstant("BuySparePartManufacturer");
        let partsToBuy = parts.filter(x => x.orderSparePart.value);
        for (const item of partsToBuy) {
            if (item.replaceWithDifferent.value) {
                const partNumber = item.newPartNumber.value
                if (item.newManufacturer.value && item.newManufacturer.value.toLowerCase() == defaultManufacturer && partNumber) {
                    let existingPartCount = result[partNumber] ?? 0;
                    result[partNumber] = existingPartCount + item.quantity.value;
                }
            } else {
                const partNumber = item.partNumber.value
                if (item.manufacturer.value && item.manufacturer.value.toLowerCase() == defaultManufacturer && partNumber) {
                    let existingPartCount = result[partNumber] ?? 0;
                    result[partNumber] = existingPartCount + item.quantity.value;
                }
            }
        }
        return result;
    }

    const generateCSV = () => {
        let csvContent = "data:text/csv;charset=utf-8," + partsCsvData.map(e => e.join(";")).join("\n");
        saveAs(csvContent, "replacement-parts.csv");
    }

    const filterFunction = (tempdata) => {
        sortAndSetParts(tempdata);
    }

    const sortAndSetParts = (partList) => {
        setParts(partList.sort(replacementPartsSorter))
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getTableEditLink = (service, measurementId) => {
        let cid = fullResponse.find(x => x.measurementId == measurementId).childId;
        let tabName;
        let serviceName;
        switch (service) {
            case "compressedAirParts":
                tabName = "CAG";
                serviceName = "AddEditCompressor";
                break;
            case "dryerParts":
                tabName = "AQ";
                serviceName = "IndividualDryer";
                break;
            case "airQualityParts":
                tabName = "AQ";
                serviceName = "AirQualityMeasurement";
                break;
            case "distributionLineParts":
                tabName = "AN";
                serviceName = "DistributionLine";
                break;
            case "airReceiverParts":
                tabName = "AN";
                serviceName = "AirReceiver";
                break;
            case "pressureDropParts":
                tabName = "AN";
                serviceName = "PressureDropAnalysis";
                break;
            case "projectApplicationParts":
                tabName = "MACAC";
                serviceName = "Application";
                break;
            case "leakageParts":
                tabName = "LDAR";
                serviceName = "Leakage";
                break;
            default:
                return "";
        }
        return "/" + country + "/" + lng + '/project/' + id + '/' + tabName + '/' + serviceName + '/' + cid + "#Tabs";
    }

    return <div className="ReplacementPartsCart">
        <Grid className="top-bar breadcrumb-container" container direction="row">
            <BreadcrumbsComponent />
        </Grid>
        <Container>
            <div className="single-table-page">
                <Grid container direction="column">
                    <Grid item>
                        <HeadingComponent value={t('OverviewReplacementParts')} size="h6" />
                    </Grid>
                    <Grid item xs={12} style={{ margin: "15px 0" }}>
                        <TableHeadingContent
                            defaultData={parts}
                            filterData={filterData}
                            onFilterFunction={filterFunction}
                            filterModel={FilterModel.ReplacmentPartsCart}
                            NotShowAddDelete={true}
                            tableId={"ReplacmentPartsCart"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={3} className="table-rightborder">
                                        </TableCell>
                                        <TableCell colSpan={3} align="center" className="table-rightborder">
                                            {t('Location')}
                                        </TableCell>
                                        <TableCell colSpan={4} align="center" className="table-rightborder">
                                            {t('DefectedDevice')}
                                        </TableCell>
                                        <TableCell colSpan={6} align="center">
                                            {t('ReplacementDevice')}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {
                                            ReplacementPartsCartHeadings.map((el, fieldIndex) => {
                                                return <TableCell key={'heading' + el + fieldIndex} className={(fieldIndex == 2 || fieldIndex == 5 || fieldIndex == 9) ? "table-rightborder" : ""}>
                                                    {t(el)}
                                                </TableCell>
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        parts.slice(page * rowsPerPage, rowsPerPage + (page * rowsPerPage)).map((part, rowIndex) =>
                                            <TableRow key={rowIndex}>
                                                <TableCell id={"RPCART-service" + rowIndex}>{t(part.service.value)}</TableCell>
                                                <TableCell id={"RPCART-no" + rowIndex}>{part.no.value}</TableCell>
                                                <TableCell id={"RPCART-measurementId" + rowIndex} className="table-rightborder">
                                                    <LinkComponent
                                                        link={getTableEditLink(part.service.value, part.measurementId.value)}
                                                        innerComponent={part.measurementId.value}
                                                    />
                                                </TableCell>
                                                <TableCell id={"RPCART-building" + rowIndex}>{part.building.value}</TableCell>
                                                <TableCell id={"RPCART-department" + rowIndex}>{part.department.value}</TableCell>
                                                <TableCell id={"RPCART-machine" + rowIndex} className="table-rightborder">{part.machine.value}</TableCell>
                                                <TableCell id={"RPCART-partNumber" + rowIndex}>{part.partNumber.value}</TableCell>
                                                <TableCell id={"RPCART-typeCode" + rowIndex}>{part.typeCode.value}</TableCell>
                                                <TableCell id={"RPCART-component" + rowIndex}>{part.component.value}</TableCell>
                                                <TableCell id={"RPCART-manufacturer" + rowIndex} className="table-rightborder">{part.manufacturer.value}</TableCell>
                                                <TableCell id={"RPCART-newPartNumber" + rowIndex}>{part.replaceWithDifferent.value ? part.newPartNumber.value : part.partNumber.value}</TableCell>
                                                <TableCell id={"RPCART-newTypeCode" + rowIndex}>{part.replaceWithDifferent.value ? part.newTypeCode.value : part.typeCode.value}</TableCell>
                                                <TableCell id={"RPCART-newComponent" + rowIndex}>{part.replaceWithDifferent.value ? part.newComponent.value : part.component.value}</TableCell>
                                                <TableCell id={"RPCART-newManufacturer" + rowIndex}>{part.replaceWithDifferent.value ? part.newManufacturer.value : part.manufacturer.value}</TableCell>
                                                <TableCell id={"RPCART-quantity" + rowIndex}>{part.quantity.value}</TableCell>
                                                <TableCell id={"RPCART-orderSparePart" + rowIndex}>
                                                    <SimpleCheckbox
                                                        disabled
                                                        id={"ReplacmentParts-orderSparePart" + rowIndex}
                                                        key={Math.random()}
                                                        defaultChecked={part.orderSparePart.value}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }

                                </TableBody>
                                {parts.length == 0 &&
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={10}>
                                                <div>{t('NoData')}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                }

                            </Table>
                            {parts.length > 0 &&
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    component="div"
                                    count={parts && parts.length > 0 ? parts.length : 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            }
                        </TableContainer>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justifyContent="flex-end" style={{ margin: "20px 0" }}>
                            <Grid item>
                                <ButtonComponent
                                    id="RPCART-cancel"
                                    value={t('Cancel')}
                                    color="secondary"
                                    onChange={clickCancel}
                                />
                            </Grid>
                            {(Object.keys(projectInfo).length > 0 &&
                                (projectInfo.projectInfo.hideButtonBuyReplacementParts === false ||
                                    projectInfo.projectRights.isSuperAdmin ||
                                    projectInfo.projectRights.isExecutorAdmin ||
                                    projectInfo.projectRights.isTechnician)
                            ) &&
                                <Grid item>
                                    <ButtonComponent
                                        id="RPCART-buy"
                                        value={t('BuySpareParts')}
                                        color="primary"
                                        icon={<ShoppingCart style={{ color: "white" }} />}
                                        onChange={buySpareParts}
                                    />
                                </Grid>
                            }
                            <Grid item>
                                <ButtonComponent
                                    id="RPCART-csv"
                                    value={t('CopyAllToClipboard')}
                                    color="primary"
                                    onChange={generateCSV}
                                />
                            </Grid>
                            <Grid item>
                                <SparePartsToExcel data={parts}></SparePartsToExcel>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Container>
    </div>
}

