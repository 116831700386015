export const first = "first";
export const middle = "middle";
export const last = "last";
export const titleWidth = 15;

export interface BorderStyleRules {
  first: BorderStyles;
  middle: BorderStyles;
  last: BorderStyles;
  numFmt?: string;
}

export interface BorderStyle {
  style: string,
  fgColor: {
    rgb: string
  }
}

export interface BorderStyles {
  top?: BorderStyle;
  bottom?: BorderStyle;
  left?: BorderStyle;
  right?: BorderStyle;
}

export const leftThickOnly: BorderStyles = {
  right: { style: "thick", fgColor: { rgb: "FFFF0000" } }
}

export const basicBorder: BorderStyles = {
  top: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  bottom: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  left: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  right: { style: "thin", fgColor: { rgb: "FFFF0000" } },
};

export const leftThick: BorderStyles = {
  top: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  bottom: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  left: { style: "thick", fgColor: { rgb: "FFFF0000" } },
  right: { style: "thin", fgColor: { rgb: "FFFF0000" } },
};
export const rightThick: BorderStyles = {
  top: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  bottom: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  left: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  right: { style: "thick", fgColor: { rgb: "FFFF0000" } },
};

export const bottomThick: BorderStyles = {
  top: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  bottom: { style: "thick", fgColor: { rgb: "FFFF0000" } },
  left: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  right: { style: "thin", fgColor: { rgb: "FFFF0000" } },
};

export const topThick: BorderStyles = {
  top: { style: "thick", fgColor: { rgb: "FFFF0000" } },
  bottom: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  left: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  right: { style: "thin", fgColor: { rgb: "FFFF0000" } },
};

export const topRightThick: BorderStyles = {
  top: { style: "thick", fgColor: { rgb: "FFFF0000" } },
  bottom: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  left: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  right: { style: "thick", fgColor: { rgb: "FFFF0000" } },
};

export const bottomRightThick: BorderStyles = {
  top: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  bottom: { style: "thick", fgColor: { rgb: "FFFF0000" } },
  left: { style: "thin", fgColor: { rgb: "FFFF0000" } },
  right: { style: "thick", fgColor: { rgb: "FFFF0000" } },
};

