import { GridColDef, GridValidRowModel, GridEditInputCell, GridPreProcessEditCellProps, GridRenderEditCellParams, GridRowId, GridSingleSelectColDef, ValueOptions, GridRowModel, GridApiPro } from '@mui/x-data-grid-pro';
import { IDropdownItem, Translate, ModelParam, ModelFactory } from '../Types/Types';
// @ts-expect-error not converted yet
import * as Mapper from '../../helper/Mappers';
import * as DateHelper from '../../helper/DateHelper';
import { Tooltip } from '@mui/material';
import React from 'react';
import _ from 'lodash';

type CustomGridColDef = <T>(model: ModelFactory<T>, t: Translate, field: keyof T, custom?: Partial<GridColDef>) => GridColDef;
type YesNoGridSingleSelectColDef = <T>(model: ModelFactory<T>, t: Translate, field: keyof T, custom?: Partial<GridSingleSelectColDef>) => GridSingleSelectColDef;
type ItemsGridSingleSelectColDef = <T>(model: ModelFactory<T>, t: Translate, field: keyof T, dropdownItems: IDropdownItem[], custom?: Partial<GridSingleSelectColDef>) => GridSingleSelectColDef;

export type ChangesRef = { unsavedRows: Record<GridRowId, GridValidRowModel>, rowsBeforeChange: Record<GridRowId, GridValidRowModel> }

export const defaultRefValue = { unsavedRows: {}, rowsBeforeChange: {} };

export const sxStyle = {
    '& .MuiDataGrid-row.row--added': { backgroundColor: 'rgba(207, 255, 171, 0.3)' },
    '& .MuiDataGrid-row.row--edited': { backgroundColor: 'rgba(255, 254, 176, 0.3)' },
    '& .MuiDataGrid-row.row--removed': { backgroundColor: 'rgba(255, 170, 170, 0.3)' },
};

export const getRowClassName = (gridRowId: GridRowId, changesRef: React.MutableRefObject<ChangesRef>) => {
    const unsavedRow = changesRef.current.unsavedRows[gridRowId];
    if (unsavedRow) {
        if (unsavedRow._action === 'delete') {
            return 'row--removed';
        }
        if (unsavedRow.id === 0) {
            return 'row--added';
        }
        return 'row--edited';
    }
    return '';
}

export const valueColDef: CustomGridColDef = (model, t, field, custom) => {
    const modelParam = model[field];
    return {
        minWidth: 100,
        maxWidth: 300,
        field: field as string,
        headerName: modelParam?.label,
        headerClassName: 'datagrid-bold',
        ...custom
    }
};

export const inputColDef: CustomGridColDef = (model, t, field, custom) => {
    const modelParam = model[field];
    return {
        minWidth: 100,
        maxWidth: 300,
        field: field as string,
        headerName: modelParam?.label,
        headerClassName: 'datagrid-bold',
        editable: true,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return modelParam.isValid ?
                (<GridEditInputCell {...params} />) :
                (<Tooltip title={modelParam.validationError}><GridEditInputCell {...params} /></Tooltip>)
        },
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            modelParam.value = params.props.value;
            const mappedModel = Mapper.mapDataToModelValues(params.row, model);
            const isValid = validateSingleParam(modelParam, mappedModel);
            modelParam.isValid = isValid;
            return { ...params.props, error: !isValid };
        },
        ...custom
    }
};

export const datesColDef: CustomGridColDef = (model, t, field, custom) => {
    const modelParam = model[field];

    return {
        minWidth: 110,
        maxWidth: 300,
        field: field as string,
        headerName: modelParam?.label,
        valueGetter: (value: any) => value ? new Date(value) : value,
        valueFormatter: (value: any) => DateHelper.FormatDate(value),
        headerClassName: 'datagrid-bold',
        editable: true,
        type: 'date',
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            modelParam.value = params.props.value;
            const mappedModel = Mapper.mapDataToModelValues(params.row, model);
            const isValid = validateSingleParam(modelParam, mappedModel);
            modelParam.isValid = isValid;
            return { ...params.props, error: !isValid };
        },
        ...custom
    }
};

export const yesNoColDef: YesNoGridSingleSelectColDef = (model, t, field, custom) => {
    const modelParam = model[field];
    return {
        minWidth: 100,
        maxWidth: 300,
        field: field as string,
        headerName: modelParam?.label,
        valueOptions: [{ value: true, label: t('Yes') }, { value: false, label: t('No') }],
        getOptionValue: (value: any) => value?.value,
        getOptionLabel: (value: any) => value?.label,
        headerClassName: 'datagrid-bold',
        type: 'singleSelect',
        editable: true,
        ...custom
    }
};

export const itemsColDef: ItemsGridSingleSelectColDef = (model, t, field, dropdownItems, custom) => {
    const modelParam = model[field];
    return {
        minWidth: 135,
        maxWidth: 300,
        field: field as string,
        headerName: modelParam?.label,
        valueOptions: transformDropdownItems(dropdownItems),
        valueGetter: (value: IDropdownItem) => value?.id,
        valueSetter: (value: number, row: object) => ({ ...row, [field]: transformValueOption(dropdownItems, value) }),
        getOptionValue: (value: any) => value?.value,
        getOptionLabel: (value: any) => t(value?.label),
        headerClassName: 'datagrid-bold',
        type: 'singleSelect',
        editable: true,
        ...custom
    }
};

const validateSingleParam = <T, TT>(modelParam: ModelParam<T, TT>, model: ModelFactory<T>) => {
    let isValid = true;
    modelParam.isValid = true;
    modelParam.validators.forEach((v: any) => {
        if (v.length == 1 && !v(modelParam.value)) {
            modelParam.isValid = false;
            isValid = false;
        }
        else if (v.length == 2 && !v(modelParam.value, model)) {
            modelParam.isValid = false;
            isValid = false;
        }
    })
    return isValid;
}

export const processRowUpdate = <T extends GridRowModel>(
    changesRef: React.MutableRefObject<ChangesRef>,
    setHasChanges: React.Dispatch<React.SetStateAction<boolean>>
) => {
    return async (newRow: T, oldRow: T): Promise<T> => {
        const rowId = newRow.gridId;
        changesRef.current.unsavedRows[rowId] = newRow;
        if (!changesRef.current.rowsBeforeChange[rowId] && !(oldRow.id === 0)) {
            changesRef.current.rowsBeforeChange[rowId] = oldRow;
        }
        setHasChanges(true);
        return newRow;
    };
};

export const discardChanges = (
    apiRef: React.MutableRefObject<GridApiPro>,
    changesRef: React.MutableRefObject<ChangesRef>,
    columns: GridColDef[],
    setHasChanges: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    apiRef.current.getAllRowIds().forEach((id) => {
        columns.forEach((item) => {
            if (apiRef.current.getCellMode(id, item.field) === 'edit') {
                apiRef.current.stopCellEditMode({ id: id, field: item.field, ignoreModifications: true });
            }
        });
    });
    [changesRef.current.unsavedRows].forEach((item) => {
        Object.values(item).forEach((row) => {
            if (row.id === 0) {
                row._action = 'delete';
                apiRef.current.updateRows([row]);
            }
        });
    });
    [changesRef.current.rowsBeforeChange].forEach((item) => {
        Object.values(item).forEach((row) => {
            if (!_.isEmpty(row)) {
                apiRef.current.updateRows([row]);
            }
        });
    });
    changesRef.current = { unsavedRows: {}, rowsBeforeChange: {} };
    setHasChanges(false);
};

function transformValueOption(dropdownItems: IDropdownItem[], value: number): IDropdownItem {
    if (value < 1) {
        return {
            id: 0,
            value: ""
        };
    }
    return {
        id: dropdownItems[value - 1]?.id ?? 0,
        value: dropdownItems[value - 1]?.value ?? ""
    };
}

function transformDropdownItems(dropdownItems: IDropdownItem[]): ValueOptions[] {
    return dropdownItems.map((item) => ({
        value: item.id,
        label: item.value,
    }));
}