import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Watermark from './Watermark';
import { usePdfStyles } from './PdfStyles';

const styles = StyleSheet.create({
    item: {
        display: "flex",
        flexDirection: "row",
        fontSize: "10px"
    },
    middle: {
        borderBottom: "1px dotted #aaa",
        flexGrow: 1,
        marginBottom: "1px"
    },
});

export const TableOfContentPdf = (props) => {
    const { data, inquiry, pdfFiles } = props;
    const { t } = useTranslation();
    const PdfStyles = usePdfStyles();
    
    return (
        <Page size={props.pageSize} style={PdfStyles.page} wrap>
            {inquiry === false && <Watermark projectData={data} />}
            <Text style={PdfStyles.pageNumbers} render={({ pageNumber, totalPages }) => (pageNumber + ' ' + t('of') + ' ' + totalPages)} fixed />
            <View style={PdfStyles.content}>
                <Text style={{ fontSize: "16px" }}>1. {t('Content')}</Text>
                <View style={{ height: "32px" }} ></View>
                {props.tocValues.tocNumbering.executiveSummary != undefined &&
                    <View>
                        <View style={styles.item}>
                            <Text style={PdfStyles.bold}>{props.tocValues.tocNumbering.executiveSummary}. {t('ExecutiveSummary')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.ExecutiveSummary || 'n'}</Text>
                        </View>
                        {props.tocValues.executiveSummarySections.generalInformation != undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.executiveSummary}.{props.tocValues.executiveSummarySections.generalInformation}. {t('GeneralInformation')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.GeneralInformation ?? 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.executiveSummarySections.compressedAirGeneration != undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.executiveSummary}.{props.tocValues.executiveSummarySections.compressedAirGeneration}. {t('CompressedAirGeneration')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.CompressedAirGeneration ?? 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.executiveSummarySections.airQuality != undefined &&
                            <View>
                                <View style={styles.item}>
                                    <Text>{props.tocValues.tocNumbering.executiveSummary}.{props.tocValues.executiveSummarySections.airQuality}. {t('AirQuality')}</Text>
                                    <Text style={styles.middle}></Text>
                                    <Text>{props.tocValues.AirQualitySummary ?? 'n'}</Text>
                                </View>
                                {props.tocValues.executiveSummarySections.dryer != undefined &&
                                    <View style={styles.item}>
                                        <Text>{props.tocValues.tocNumbering.executiveSummary}.{props.tocValues.executiveSummarySections.airQuality}.{props.tocValues.executiveSummarySections.dryer}. {t('Dryers')}</Text>
                                        <Text style={styles.middle}></Text>
                                        <Text>{props.tocValues.Dryers ?? 'n'}</Text>
                                    </View>
                                }
                                {props.tocValues.executiveSummarySections.measurement != undefined &&
                                    <View style={styles.item}>
                                        <Text>{props.tocValues.tocNumbering.executiveSummary}.{props.tocValues.executiveSummarySections.airQuality}.{props.tocValues.executiveSummarySections.measurement}. {t('AirQualityMeasurement')}</Text>
                                        <Text style={styles.middle}></Text>
                                        <Text>{props.tocValues.AirQualityMeasurementSummary ?? 'n'}</Text>
                                    </View>
                                }
                            </View>
                        }
                        {props.tocValues.executiveSummarySections.airNetwork != undefined &&
                            <View>
                                <View style={styles.item}>
                                    <Text>{props.tocValues.tocNumbering.executiveSummary}.{props.tocValues.executiveSummarySections.airNetwork}. {t('AirNetwork')}</Text>
                                    <Text style={styles.middle}></Text>
                                    <Text>{props.tocValues.AirNetworkSummary ?? 'n'}</Text>
                                </View>
                                {props.tocValues.executiveSummarySections.distributionLine != undefined &&
                                    <View style={styles.item}>
                                        <Text>{props.tocValues.tocNumbering.executiveSummary}.{props.tocValues.executiveSummarySections.airNetwork}.{props.tocValues.executiveSummarySections.distributionLine}. {t('DistributionLine')}</Text>
                                        <Text style={styles.middle}></Text>
                                        <Text>{props.tocValues.DistributionLine ?? 'n'}</Text>
                                    </View>
                                }
                                {props.tocValues.executiveSummarySections.airReceiver != undefined &&
                                    <View style={styles.item}>
                                        <Text>{props.tocValues.tocNumbering.executiveSummary}.{props.tocValues.executiveSummarySections.airNetwork}.{props.tocValues.executiveSummarySections.airReceiver}. {t('AirReceivers')}</Text>
                                        <Text style={styles.middle}></Text>
                                        <Text>{props.tocValues.AirReceiversSummary ?? 'n'}</Text>
                                    </View>
                                }
                                {props.tocValues.executiveSummarySections.pressureDrop != undefined &&
                                    <View style={styles.item}>
                                        <Text>{props.tocValues.tocNumbering.executiveSummary}.{props.tocValues.executiveSummarySections.airNetwork}.{props.tocValues.executiveSummarySections.pressureDrop}. {t('PressureDropAnalysis')}</Text>
                                        <Text style={styles.middle}></Text>
                                        <Text>{props.tocValues.PressureDrop ?? 'n'}</Text>
                                    </View>
                                }
                            </View>
                        }
                        {props.tocValues.executiveSummarySections.macac != undefined &&
                            <View>
                                <View style={styles.item}>
                                    <Text>{props.tocValues.tocNumbering.executiveSummary}.{props.tocValues.executiveSummarySections.macac}. {t('MachineAnalysisForEnergyEfficiency')}</Text>
                                    <Text style={styles.middle}></Text>
                                    <Text>{props.tocValues.MACAC ?? 'n'}</Text>
                                </View>
                                {props.tocValues.executiveSummarySections.application != undefined &&
                                    <View style={styles.item}>
                                        <Text>{props.tocValues.tocNumbering.executiveSummary}.{props.tocValues.executiveSummarySections.macac}.{props.tocValues.executiveSummarySections.application}. {t('Applications')}</Text>
                                        <Text style={styles.middle}></Text>
                                        <Text>{props.tocValues.Applications ?? 'n'}</Text>
                                    </View>
                                }
                            </View>
                        }
                        {props.tocValues.executiveSummarySections.leakageAnalysis != undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.executiveSummary}.{props.tocValues.executiveSummarySections.leakageAnalysis}. {t('LeakageAnalysis')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.LeakageAnalysis ?? 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.executiveSummarySections.ldar != undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.executiveSummary}.{props.tocValues.executiveSummarySections.ldar}. {t('LeakageDetectionAndRepair')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.LeakageDetectionAndDocumentation || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.executiveSummarySections.costSavings != undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.executiveSummary}.{props.tocValues.executiveSummarySections.costSavings}. {t('Recommendations')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.CostSavingsExec || 'n'}</Text>
                            </View>
                        }
                    </View>
                }
                {props.tocValues.tocNumbering.generalInformation != undefined &&
                    <View>
                        <View style={styles.item}>
                            <Text style={PdfStyles.bold}>{props.tocValues.tocNumbering.generalInformation}. {t('GeneralInformation')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.CustomerInformation || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.generalInformation}.1. {t('CustomerInformation')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.CustomerInformation || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.generalInformation}.2. {t('WorkingTimeInHours')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.WorkingTimeInHours || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.generalInformation}.3. {t('AmbientClimaticConditionsHeader')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.AmbientClimaticConditionsHeader || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.generalInformation}.4. {t('EnergyCost')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.EnergyCosts || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.generalInformation}.5. {t('AirSupplyTime')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.AirSupplyTime || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.generalInformation}.6. {t('PercentegeofCompressedAir')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.PercentegeofCompressedAir || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.generalInformation}.7. {t('Maintenance')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.Maintenance || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.generalInformation}.8. {t('ProcessHeat')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.ProcessHeat || 'n'}</Text>
                        </View>
                    </View>
                }
                {props.tocValues.tocNumbering.cag != undefined &&
                    <View>
                        <View style={styles.item}>
                            <Text style={PdfStyles.bold}>{props.tocValues.tocNumbering.cag}. {t('CompressedAirGeneration')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.IndividualCompressorDetails || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.cag}.1. {t('IndividualCompressorDetails')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.IndividualCompressorDetails || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.cag}.2. {t('CompressorCapacity')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.CompressorCapacity || 'n'}</Text>
                        </View>
                        {props.tocValues.tocNumbering.cagChildren[3] !== undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[3]}. {t('CompressorExpectedOutput')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.CompressorExpectedOutput || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.cagChildren[4] !== undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[4]}. {t('MeasurementOfTheCompressedAirGeneration')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.MeasurementCompressedAirGeneration || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.cagChildren[5] !== undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[5]}. {t('Workload')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.CAGWorkload || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.cagChildren[6] !== undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[6]}. {t('CompressorRoomAverageWorkloadPerDay')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.CompressorRoomAverageWorkloadPerDay || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.cagChildren[7] !== undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[7]}. {t('StaticConsumptionHeader')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.StaticConsumptionHeader || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.cagChildren[8] !== undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[8]}. {t('CompressorUnloadTime')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.CompressorUnloadTime || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.cagChildren[9] !== undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[9]}. {t('AverageAirFlowAndReserve')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.AirFlowAndReserve || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.cagChildren[10] !== undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[10]}. {t('CompressorAverageAirFlowPerDay')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.AverageAirFlowPerCompressor || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.cagChildren[11] !== undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[11]}. {t('CompressorRoomAverageAirFlowPerDay')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.AverageAirFlowPerCompressorRoom || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.cagChildren[12] !== undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[12]}. {t('SpecificPowerOfTheSystem')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.SpecificPowerOfTheSystem || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.cagChildren[13] !== undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[13]}. {t('YearlyConsumption')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.YearlyConsumptionCalculated || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.cagChildren[14] !== undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[14]}. {t('CostsPerUnit')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.CostPerUnit || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.cagChildren[15] !== undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[15]}. {t('CostPerYear')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.CostPerYear || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.cagChildren[16] !== undefined &&
                            <View style={styles.item} >
                                <Text>{props.tocValues.tocNumbering.cag}.{props.tocValues.tocNumbering.cagChildren[16]}. {t('AirConsumptionProfile')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.AirConsumptionProfile || 'n'}</Text>
                            </View>
                        }
                    </View>
                }
                {props.tocValues.tocNumbering.airQuality != undefined &&
                    <View>
                        <View style={styles.item}>
                            <Text style={PdfStyles.bold}>{props.tocValues.tocNumbering.airQuality}. {t('AirQuality')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.AirQuality || 'n'}</Text>
                        </View>
                        {props.tocValues.tocNumbering.InstalledDryers != undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.airQuality}.{props.tocValues.tocNumbering.InstalledDryers}. {t('InstalledDryers')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.InstalledDryers || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.AirQualityMeasurement != undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.airQuality}.{props.tocValues.tocNumbering.AirQualityMeasurement}. {t('AirQualityMeasurement')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.AirQualityMeasurement || 'n'}</Text>
                            </View>
                        }
                    </View>
                }
                {props.tocValues.tocNumbering.airNetwork != undefined &&
                    <View>
                        <View style={styles.item}>
                            <Text style={PdfStyles.bold}>{props.tocValues.tocNumbering.airNetwork}. {t('AirNetwork')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.AirNetwork || 'n'}</Text>
                        </View>
                        {props.tocValues.tocNumbering.DistributionLine != undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.airNetwork}.{props.tocValues.tocNumbering.DistributionLine}. {t('DistributionLine')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.AirNetwork || 'n'}</Text>
                            </View>
                        }
                        {props.tocValues.tocNumbering.AirReceivers != undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.airNetwork}.{props.tocValues.tocNumbering.AirReceivers}. {t('AirReceivers')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.AirReceivers || 'n'}</Text>
                            </View>
                        }
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.airNetwork}.{props.tocValues.tocNumbering.StorageAirCapacity}. {t('StorageAirCapacity')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.StorageAirCapacity || 'n'}</Text>
                        </View>
                        {props.tocValues.tocNumbering.PressureDropAnalysis != undefined &&
                            <View style={styles.item}>
                                <Text>{props.tocValues.tocNumbering.airNetwork}.{props.tocValues.tocNumbering.PressureDropAnalysis}. {t('PressureDropAnalysis')}</Text>
                                <Text style={styles.middle}></Text>
                                <Text>{props.tocValues.pressureDropParts || 'n'}</Text>
                            </View>
                        }
                    </View>
                }
                {props.tocValues.tocNumbering.macac != undefined &&
                    <View>
                        <View style={styles.item}>
                            <Text style={PdfStyles.bold}>{props.tocValues.tocNumbering.macac}. {t('MachineAnalysisForEnergyEfficiency')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.MachineAnalysis || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.macac}.1. {t('Results')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.TOTAL || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.macac}.2. {t('MA/CACPageTitle')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.CACPageTitle || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.macac}.3. {t('PaybackTime')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.MACACPaybackTime || 'n'}</Text>
                        </View>
                    </View>
                }
                {props.tocValues.tocNumbering.leakageAnalysis != undefined &&
                    <View>
                        <View style={styles.item}>
                            <Text style={PdfStyles.bold}>{props.tocValues.tocNumbering.leakageAnalysis}. {t('LeakageAnalysis')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.Leakage || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.leakageAnalysis}.1. {t('LeakageLevelCosts')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.LeakageLevelCosts || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.leakageAnalysis}.2. {t('LeakageDetectionAndRepairCostsPayback')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.LeakageTotalCosts || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.leakageAnalysis}.3. {t('TotalCosts')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.AnnualSavingsAfterTaxes || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.leakageAnalysis}.3.1. {t('LeakageDetectionAndRepairCosts')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.AnnualSavingsAfterTaxes || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.leakageAnalysis}.3.2. {t('AnnualSavingsAfterTaxes')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.AnnualSavingsAfterTaxes || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.leakageAnalysis}.3.3. {t('PaybackTime')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.LeakageAnalysisPaybackTime || 'n'}</Text>
                        </View>
                    </View>
                }
                {props.tocValues.tocNumbering.ldar != undefined &&
                    <View>
                        <View style={styles.item}>
                            <Text style={PdfStyles.bold}>{props.tocValues.tocNumbering.ldar}. {t('LeakageDetectionAndRepair')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.LeakageDetection || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.ldar}.1. {t('Results')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.LeakageDetectionResults || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.ldar}.2. {t('ListOfLeakages')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.LeakageDetectionList || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.ldar}.3. {t('LeakageDetectionCharts')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.LeakageDetectionCharts || 'n'}</Text>
                        </View>
                    </View>
                }
                {props.tocValues.tocNumbering.costSavings != undefined &&
                    <View>
                        <View style={styles.item}>
                            <Text style={PdfStyles.bold}>{props.tocValues.tocNumbering.costSavings}. {t('CostSavings')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.SavingsLeakages || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.costSavings}.1. {t('SavingsEliminatingTheLeakagesTable')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.SavingsLeakages || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.costSavings}.2. {t('SavingsShuttingOffMainAirSupplyAtNonProductionTimeTable')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.SavingsShuttingOffMainAirSupply || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.costSavings}.3. {t('SavingsReducingThePressureDropTable')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.SavingsReducingThePressureDrop || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.costSavings}.4. {t('SavingsReducingThePressureLevelOfTheSystemTable')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.SavingsReducingThePressureLevelOfTheSystem || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.costSavings}.5. {t('SavingsOptimizingTheAirConsumptionAtSingleApplicationsTable')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.SavingsOptimizingTheAirConsumptionAtSingleApplications || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.costSavings}.6. {t('CumulativeHighestSavingsPerYearTable')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.CumulativeHighestSavingsPerYear || 'n'}</Text>
                        </View>
                        <View style={styles.item}>
                            <Text>{props.tocValues.tocNumbering.costSavings}.7. {t('CostSavingsChart')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.CostSavingsChart || 'n'}</Text>
                        </View>
                    </View>
                }
                {props.tocValues.tocNumbering.replacementParts != undefined &&
                    <View>
                        <View style={styles.item}>
                            <Text style={PdfStyles.bold}>{props.tocValues.tocNumbering.replacementParts}. {t('ReplacementParts')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.replacementParts || 'n'}</Text>
                        </View>
                    </View>
                }
                {(props.tocValues.tocNumbering.attachments != undefined && pdfFiles && pdfFiles.length > 0) &&
                    <View>
                        <View style={styles.item}>
                            <Text style={PdfStyles.bold}>{props.tocValues.tocNumbering.attachments}. {t('Attachments')}</Text>
                            <Text style={styles.middle}></Text>
                            <Text>{props.tocValues.attachments || 'n'}</Text>
                        </View>
                    </View>
                }
            </View>
        </Page>
    )
}
