import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppBar, Tabs, Tab, Container } from "@mui/material";
import ProjectView from "../ProjectView/ProjectView"
import { useHistory, useLocation, useParams } from "react-router-dom";
import TopContentContainer from "../TopContentConainer/TopContentContainer";
import * as Roles from '../../helper/AuthorizationHelper/AuthConstatants';
import { HasUserRole } from '../../helper/AuthorizationHelper/AuthHelper';
import { UserStore } from '../../store';
import "./ProjectMenu.css";

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

export default function ProjectMenu(props) {
    const { menuData } = props;

    const { t } = useTranslation();
    let history = useHistory();
    let location = useLocation();
    let { country, lng, id } = useParams();
    //For outside click of popup
    const [activeTab, setTab] = useState(0);
    const [user, setUser] = useState({})

    function GetTabFromPath(page) {
        if (page === "PS") {
            return 0;
        } else if (page === "GI") {
            return 1;
        } else if (page === "CAG") {
            return 2;
        } else if (page === "AQ") {
            return 3;
        } else if (page === "AN") {
            return 4;
        } else if (page === "MACAC") {
            return 5;
        } else if (page === "LA") {
            return 6;
        } else if (page === "LDAR" || page === "MassEditLDAR") {
            return 7;
        } else if (page === "CS") {
            return 8;
        } else {
            return 0;
        }
    }

    useEffect(() => {
        let userSub = UserStore.user.subscribe(user => setUser(user));
        let paths = location.pathname.split("/");
        setTab(GetTabFromPath(paths[5]));
        return () => {
            userSub.unsubscribe();
        }
    }, [location]);

    const getMenuTabLink = (tab) => {
        switch (tab) {
            case 0:
                {
                    return `/${country}/${lng}/project/${id}/PS`;
                }
            case 1:
                {
                    return `/${country}/${lng}/project/${id}/GI`;
                }
            case 2:
                {
                    return `/${country}/${lng}/project/${id}/CAG`;
                }
            case 3:
                {
                    return `/${country}/${lng}/project/${id}/AQ`;
                }
            case 4:
                {
                    return `/${country}/${lng}/project/${id}/AN`;
                }
            case 5:
                {
                    return `/${country}/${lng}/project/${id}/MACAC`;
                }
            case 6:
                {
                    return `/${country}/${lng}/project/${id}/LA`;
                }
            case 7:
                {
                    return `/${country}/${lng}/project/${id}/LDAR`;
                }
            case 8:
                {
                    return `/${country}/${lng}/project/${id}/CS`;
                }
            default:
                {
                    return `/${country}/${lng}/project/${id}`;
                }
        }
    };

    const setMenuTab = (e, tab) => {
        e.preventDefault();
        var url = getMenuTabLink(tab);
        history.push(url);
    };

    return (
        <div className="projectMenu">
            <div className="projectMenu-body">
                <Container>
                    {menuData &&
                        <TopContentContainer
                            ContentComponent={
                                <ProjectView
                                    checklist={{
                                        compressedAirGeneration: menuData.isAnalysisOfTheCompressedAirGenerationSelected,
                                        airQuality: menuData.isCompressedAirQualityAnalysisSelected,
                                        airNetwork: menuData.isAirNetworkAnalysisSelected,
                                        macac: menuData.isMachineAnalysisForEnergyEfficiencySelected,
                                        ldar: menuData.isLeakageDetectionAndDocumentationSelected
                                    }}
                                    menuData={menuData}
                                />
                            }
                            showExecutor={true}
                        />
                    }
                    <div className="projectMenu-tab" id="#Tabs">
                        <AppBar position="static" color="default">
                            <Tabs id="tabs_Container"
                                value={activeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                {HasUserRole([Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician], user) &&
                                    <Tab
                                        label={t('ProjectSettings')}
                                        {...a11yProps(0)}
                                        className={`tab-button ${activeTab == 0 ? "active-projectMenu-tab" : ""
                                            }`}
                                        href={getMenuTabLink(0)}
                                        onClick={e => { setMenuTab(e, 0) }}
                                    />
                                }
                                <Tab
                                    label={t('GeneralInformation')}
                                    {...a11yProps(1)}
                                    className={`tab-button ${activeTab == 1 ? "active-projectMenu-tab" : ""
                                        }`}
                                    href={getMenuTabLink(1)}
                                    onClick={e => { setMenuTab(e, 1) }}
                                />
                                <Tab
                                    label={t('CompressedAirGeneration')}
                                    {...a11yProps(2)}
                                    className={'tab-button ' + (activeTab == 2 && 'active-projectMenu-tab')}
                                    href={getMenuTabLink(2)}
                                    onClick={e => { setMenuTab(e, 2) }}
                                />
                                {menuData && menuData.isCompressedAirQualityAnalysisSelected &&
                                    <Tab
                                        label={t('AirQuality')}
                                        {...a11yProps(3)}
                                        className={'tab-button ' + (activeTab == 3 && 'active-projectMenu-tab')}
                                        href={getMenuTabLink(3)}
                                        onClick={e => { setMenuTab(e, 3) }}
                                    />
                                }
                                {menuData && menuData.isAirNetworkAnalysisSelected &&
                                    <Tab
                                        label={t('AirNetwork')}
                                        {...a11yProps(4)}
                                        className={'tab-button ' + (activeTab == 4 && 'active-projectMenu-tab')}
                                        href={getMenuTabLink(4)}
                                        onClick={e => { setMenuTab(e, 4) }}
                                    />
                                }
                                {menuData && menuData.isMachineAnalysisForEnergyEfficiencySelected &&
                                    <Tab
                                        label={t('MA/CACTabName')}
                                        {...a11yProps(5)}
                                        className={'tab-button ' + (activeTab == 5 && 'active-projectMenu-tab')}
                                        href={getMenuTabLink(5)}
                                        onClick={e => { setMenuTab(e, 5) }}
                                    />
                                }
                                {menuData && menuData.isLeakageDetectionAndDocumentationSelected &&
                                    <Tab
                                        label={t('LeakageAnalysis')}
                                        {...a11yProps(6)}
                                        className={'tab-button ' + (activeTab == 6 && 'active-projectMenu-tab')}
                                        href={getMenuTabLink(6)}
                                        onClick={e => { setMenuTab(e, 6) }}
                                    />
                                }
                                {menuData && menuData.isLeakageDetectionAndDocumentationSelected &&
                                    <Tab
                                        label={t('LeakageDetectionAndDocumentation')}
                                        {...a11yProps(7)}
                                        className={'tab-button ' + (activeTab == 7 && 'active-projectMenu-tab')}
                                        href={getMenuTabLink(7)}
                                        onClick={e => { setMenuTab(e, 7) }}
                                    />
                                }
                                {menuData &&
                                    menuData.isAnalysisOfTheCompressedAirGenerationSelected &&
                                    menuData.isCompressedAirQualityAnalysisSelected &&
                                    menuData.isAirNetworkAnalysisSelected &&
                                    menuData.isMachineAnalysisForEnergyEfficiencySelected &&
                                    menuData.isLeakageDetectionAndDocumentationSelected &&
                                    <Tab
                                        label={t('CostSavings')}
                                        {...a11yProps(8)}
                                        className={'tab-button ' + (activeTab == 8 && 'active-projectMenu-tab')}
                                        href={getMenuTabLink(8)}
                                        onClick={e => { setMenuTab(e, 8) }}
                                    />
                                }
                            </Tabs>
                        </AppBar>
                    </div>
                </Container>
            </div>

        </div >
    );
}
