import React, { useState, useEffect } from 'react';
import { Grid, TableCell, TableRow, TableHead, TableBody, Table, TableContainer, Paper, IconButton, TablePagination, Box } from '@mui/material';
import { TextFieldComponent, NumberInputFieldComponent, HeadingComponent, ButtonComponent, ModalComponent, SingleSelectComponent, CheckboxComponent, SimpleCheckbox, AutocompleteDropdown, PhoneNumberInputField, SingleSelectComponent2 } from '../../../Reusable'
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { API } from '../../../../helper/ApiHelper';
// @ts-expect-error not converted yet
import * as Mapper from '../../../../helper/Mappers';
import { OpenGlobalSnackbar, PrintSizes, LicenseTypes, Salutations } from '../../../../helper/GlobalVariables';
import { AccountFactory, IAccountFactory, NewExecutorProps } from './AccountModel';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { UserStore } from '../../../../store';
import { HasUserRole } from '../../../../helper/AuthorizationHelper/AuthHelper';
import * as Roles from '../../../../helper/AuthorizationHelper/AuthConstatants';
import { CountryListResponse, CountryListStore } from '../../../../store/CountryListStore';
import { useIsMount } from '../../../../helper/UseIsMount';
// @ts-expect-error not converted yet
import TableHeadingContent from '../../../Reusable/TableHeadingContent/TableHeadingContent';
import { FilterModel } from "../../../Reusable/TableHeadingContent/GlobalFilterModel";
import AccountLicenses from './AccountLicenses/AccountLicenses';
import AccountDocumentation from './AccountDocumentation/AccountDocumentation';
import * as InputRestrictions from '../../../../helper/InputRestrictions';
import * as Fmt from '../../../../helper/Formatters';
import { useGlobalState } from 'state-pool';
import { CountryParam, IDropdownItem, IdParam, LanguageParam } from '../../../Types/Types';
import { IAccountSettings } from '../../../../store/AccountStore';
import { IAccountUserModel } from './AccountUsers/AccountUserModel';


export interface IFullExecutorData extends IAccountSettings {
    standardFullAuditQnt?: number,
    professionalFullAudit?: boolean,
    standardLeakageDetectionsQnt?: number,
    proAuditExpirationTime?: Date,
    professionalLeakageDetection?: boolean,
    proLeakageExpirationTime?: Date,
}

export default function AccountEdit() {
    const execUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "account-management/account/";
    const imgBlobUrl = process.env.REACT_APP_DEVFESSIMAGESERVICES_BASE + "blob/account/";

    const fileInput = React.useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const isMount = useIsMount();
    const { country, lng, id } = useParams<CountryParam & LanguageParam & IdParam>();
    const history = useHistory();

    const [isExecutorAdmin, setIsExecutorAdmin] = useState<boolean>(false);
    const [isMainView, setIsMainView] = useState(true);
    const [executorData, setExecutorData] = useState<IFullExecutorData>({});
    const [mainModel, setMainModel] = useState<IAccountFactory>(AccountFactory(t));
    const [executorsModel, setExecutorsModel] = useState<IAccountUserModel[]>([])
    const [modalModel] = useState({ open: false, message: "" });
    const [filterData, setFilterData] = useState<IAccountUserModel[]>([]);
    const [page, setPage] = useGlobalState("ExecutorUsersPage" + id, { default: 0, persist: false });
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [checkedArray, setCheckedArray] = useState<number[]>([]);
    const [checkedArrayKey, setcheckedArrayKey] = useState<number[]>([]);
    const [user, setUser] = useState({})
    const [countryList, setCountryList] = useState<CountryListResponse[]>([]);
    const [logoUrl, setLogoUrl] = useState("");
    const [documentationOpen, setDocumentationOpen] = useState(false);

    const loadExistingData = () => {
        loadExecutor();
        API.get(`${imgBlobUrl}${id}/image`).then(resp => setLogoUrl(resp));
        API.get(`${execUrl}${id}/users`).then((resp: IAccountUserModel[]) => {
            setIsExecutorAdmin(resp.find(x => x?.email === UserStore.user?.value?.email && x.role?.value === "ExecutorAdmin") != undefined);
            setExecutorsModel(resp);
            setFilterData(resp);
        })
    }

    const loadExecutor = () => {
        API.get(`${execUrl}${id}`).then(resp => {
            Fmt.setCurrency(resp.currency);
            setExecutorData(resp);
            const mappedModel = Mapper.mapDataToModelValues(resp, AccountFactory(t));
            setMainModel({ ...mappedModel });
        })
    }

    useEffect(() => {
        Mapper.updateExistingModelFormattingLabels(mainModel, AccountFactory(t), setMainModel);
    }, [t]);

    useEffect(() => {
        if (!isMount)
            CountryListStore.initCountryList(lng);
    }, [lng]);

    useEffect(() => {
        const userSub = UserStore.user.subscribe(user => setUser(user));
        CountryListStore.initCountryList(lng);
        const countrySub = CountryListStore.countryList.subscribe(list => setCountryList(list));
        if (Number(id) > 0)
            loadExistingData();
        else
            loadClean();

        return () => {
            userSub.unsubscribe();
            countrySub.unsubscribe();
        }
    }, [id]);

    const updateModel = (property: keyof IAccountFactory, value: number | string | boolean) => {
        mainModel[property].value = value;
        mainModel[property].isValid = true;
        if (mainModel[property].validators.length > 0)
            mainModel[property].validators.forEach((v: (value: any, p2?: void | undefined) => boolean) => {
                if (v(value) === false) {
                    mainModel[property].isValid = false;
                }
            });
        setMainModel({ ...mainModel });
    };

    const clickCancelModalOk = async (): Promise<void> => {
        history.push("/" + country + "/" + lng + '/projectsandrequests');
        scrollTop();
    };

    const validateFormModel = () => {
        let isValid = true;
        Object.keys(mainModel).forEach(keyString => {
            const k = keyString as keyof IAccountFactory;
            if (mainModel[k].validators.length > 0 && (mainModel[k].dependsOn == undefined || mainModel[mainModel[k].dependsOn as keyof IAccountFactory]?.value === true))
                mainModel[k].validators.forEach((v: (value: any, p2?: void | undefined) => boolean) => {
                    if (Number(id) == 0 && NewExecutorProps.indexOf(keyString) == -1) {
                        return;
                    } else if (v(mainModel[k].value) === false) {
                        isValid = false;
                        mainModel[k].isValid = false;
                    }
                });
            else if (mainModel[k].validators?.length > 0 && mainModel[k].dependsOn != undefined && mainModel[mainModel[k].dependsOn as keyof IAccountFactory]?.value === false)
                mainModel[k].value = null;
        });
        setMainModel({ ...mainModel });
        return isValid;
    };

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const loadClean = () => {
        mainModel.tenantId.value = Mapper.getNewGuid();
        setMainModel(mainModel)
    }

    const postForm = () => {
        const data = Mapper.extractValuesFromModel(mainModel);
        const method = Number(id) == 0 ? "post" : "put"
        API({
            url: `${execUrl}${id}`,
            method,
            data,
        }).then((resp) => {
            OpenGlobalSnackbar("success", t("SaveSuccessful"));
            UserStore.initUser();

            if (typeof resp === 'number') {
                history.push(`/${country}/${lng}/SettingsPanel/ExecutorManagement/${resp}/AddEditExecutor`);
            }
            else {
                history.push(`/${country}/${lng}/SettingsPanel/ExecutorManagement/`);
            }
        })
    }

    const clickCancel = () => {
        history.push(`/${country}/${lng}/SettingsPanel/ExecutorManagement`);
    }


    const saveForm = () => {
        if (validateFormModel() === true) postForm();
        else OpenGlobalSnackbar("danger", t('ModelInvalidError'));
    }

    const updateSelectModel = (element: keyof IAccountFactory, value: number, values: IDropdownItem[]) => {
        const selectedDropdownItem = values.find(x => x.id === value);
        if (selectedDropdownItem) {
            mainModel[element].value = selectedDropdownItem;
            mainModel[element].isValid = true;
            setMainModel({ ...mainModel });
        }
    };

    const filterFunction = (tempdata: IAccountUserModel[]) => {
        setExecutorsModel(tempdata);
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isCheckedForDeletion = (index: number) => {
        return checkedArray.some(x => x == index)
    }

    const handleEditExecutorUser = (cid: number) => {
        history.push("/" + country + "/" + lng + '/SettingsPanel/ExecutorManagement/' + id + '/AddEditExecutor/User/' + cid);
    }

    const deleteExecutorUser = async () => {
        const dataUpload = {
            Ids: executorsModel.filter((x) => checkedArray.some(c => c == x.id)).map(x => x.id)
        };
        API.post(`${execUrl}${id}/delete-users`, dataUpload).then(() => {
            setCheckedArray([]);
            loadExistingData();
            OpenGlobalSnackbar("success", t("DeleteSuccesfull"));
        });
    };


    const handleCheck = (checkId: number, checkIndex: number) => {
        const isAlready = checkedArray.indexOf(checkId);
        const isAlreadyKEY = checkedArray.indexOf(checkIndex);
        if (isAlready < 0) {
            checkedArray.push(checkId);
            checkedArrayKey.push(checkIndex);
        }
        else {
            checkedArray.splice(isAlready, 1);
            checkedArrayKey.splice(isAlreadyKEY, 1);
        }
        checkedArray.sort(function (a, b) { return b - a });
        checkedArrayKey.sort(function (a, b) { return b - a });
        setCheckedArray(checkedArray);
        setcheckedArrayKey(checkedArrayKey);
    }

    const updateCurrency = (currency: string) => {
        Fmt.setCurrency(currency);
        Mapper.updateExistingModelFormattingLabels(mainModel, AccountFactory(t), setMainModel);
    }

    const uploadLogo = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
        if (e && e.target && e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            API.post(`${imgBlobUrl}${id}/image`, file).then(resp => setLogoUrl(resp));
        }
    };

    return isMainView ?
        <div>
            <AccountDocumentation
                open={documentationOpen}
                setOpen={setDocumentationOpen}
                denyEdit={!HasUserRole([Roles.SuperAdmin], user)}
            />
            <ModalComponent model={{ clickOk: clickCancelModalOk, ...modalModel }} />
            <div className="country-settings">
                {Number(id) != 0 ? (
                    <Grid container spacing={3} direction="column">
                        <Grid item xs={12}>
                            <Grid container direction="row" justifyContent="space-between">
                                <Grid item xs={12} md={6}>
                                    <Grid container direction="row">
                                        <Grid item>
                                            <HeadingComponent
                                                value={mainModel.subsidiaryFestoName?.value}
                                                size="h6"
                                            />
                                            {!mainModel.festoExecutor.value &&
                                                <React.Fragment>
                                                    <ButtonComponent
                                                        value={t("UploadLogo")}
                                                        color="primary"
                                                        onChange={() => { if (fileInput.current != null) fileInput.current.click() }}
                                                        id="btn_upload-executor-logo"
                                                    />
                                                    <input
                                                        ref={fileInput}
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={uploadLogo}
                                                    />
                                                </React.Fragment>
                                            }
                                        </Grid>
                                        {!mainModel.festoExecutor.value &&
                                            <Box
                                                display="flex"
                                                alignItems="flex-end"
                                            >
                                                <Box>
                                                    {logoUrl?.length > 0 &&
                                                        <img src={logoUrl} alt="logo" id="executor-logo" style={{ maxHeight: 150, maxWidth: 150, marginLeft: "2rem" }} />
                                                    }
                                                </Box>
                                            </Box>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <TextFieldComponent
                                                disabled
                                                t={t}
                                                model={mainModel.tenantId}
                                                upperCase
                                                id="serviceprovideredit_tenantId"
                                            />
                                        </Grid>
                                        {(HasUserRole([Roles.SuperAdmin], user) || isExecutorAdmin) &&
                                            <Grid item>
                                                <ButtonComponent
                                                    className="btn-no-left-margin"
                                                    value={t("LicensingInformation")}
                                                    color="primary"
                                                    onChange={() => setIsMainView(false)}
                                                    id="serviceprovideredit_LicensingInformation"
                                                />
                                            </Grid>
                                        }
                                        {mainModel.festoExecutor.value &&
                                            <Grid item>
                                                <ButtonComponent
                                                    className="btn-no-left-margin"
                                                    value={t("Documentation")}
                                                    color="primary"
                                                    onChange={() => setDocumentationOpen(true)}
                                                    id="serviceprovideredit_documentation"
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0} direction="column">
                                {HasUserRole([Roles.SuperAdmin], user) &&
                                    <Grid item xs={12} md={6}>
                                        <CheckboxComponent
                                            model={mainModel.festoExecutor}
                                            onChange={e => updateModel("festoExecutor", e.target.checked)}
                                            id="serviceprovideredit_festoexecutor"
                                        />
                                    </Grid>}
                                <Grid item xs={12} md={6}>
                                    <CheckboxComponent
                                        model={mainModel.hideButtonReplacementPartsList}
                                        onChange={e => updateModel("hideButtonReplacementPartsList", e.target.checked)}
                                        id="serviceprovideredit_hideButtonReplacementPartsList"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} direction="row">
                                {HasUserRole([Roles.SuperAdmin, Roles.AccountAdmin], user) &&
                                    <React.Fragment>
                                        <Grid item xs={12} md={6}>
                                            <CheckboxComponent
                                                model={mainModel.hideButtonBuyReplacementParts}
                                                onChange={e => updateModel("hideButtonBuyReplacementParts", e.target.checked)}
                                                id="serviceprovideredit_hideButtonBuyReplacementParts"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {mainModel.festoExecutor.value && <SingleSelectComponent
                                                model={mainModel.licenseType}
                                                listFromDb={LicenseTypes}
                                                t={t}
                                                onChange={(e: { target: { value: number; }; }) => updateSelectModel("licenseType", e.target.value, LicenseTypes)}
                                                id="serviceprovideredit_licenseType"
                                            />
                                            }
                                        </Grid>
                                    </React.Fragment>
                                }
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={3} direction="column">
                                        <Grid item xs={12}>
                                            <AutocompleteDropdown
                                                model={mainModel.countryID}
                                                options={countryList}
                                                optionName="countryName"
                                                optionKey="countryCode"
                                                onChange={(e: { countryCode: string | number | boolean; }) => updateModel("countryID", e?.countryCode)}
                                                id="serviceprovideredit_countryid"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SingleSelectComponent
                                                model={mainModel.printSize}
                                                listFromDb={PrintSizes}
                                                t={t}
                                                onChange={(e: { target: { value: number; }; }) => updateSelectModel("printSize", e.target.value, PrintSizes)}
                                                id="serviceprovideredit_printSize"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={3} direction="column">
                                        <Grid item xs={12}>
                                            <NumberInputFieldComponent
                                                t={t}
                                                model={mainModel.cO2GridAverage}
                                                onChange={e => updateModel("cO2GridAverage", InputRestrictions.Decimal(e.target.value, 1))}
                                                id="serviceprovideredit_c02gridaverage"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                t={t}
                                                model={mainModel.currency}
                                                onChange={e => {
                                                    updateModel("currency", e.target.value);
                                                    updateCurrency(e.target.value);
                                                }}
                                                id="serviceprovideredit_currency"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3} direction="row">
                                <Grid item xs={6} md={6}>
                                    <HeadingComponent
                                        value={t("FestoSubsidiaryinformation")}
                                        size="h6"
                                    />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <HeadingComponent
                                        value={t("LeakageDetectionCostsHeader")}
                                        size="h6"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={3} direction="column">
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                t={t}
                                                model={mainModel.subsidiaryFestoName}
                                                onChange={e => updateModel("subsidiaryFestoName", e.target.value)}
                                                id="serviceprovideredit_subsidiaryfestoname"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                t={t}
                                                model={mainModel.subsidiaryAddress}
                                                onChange={e => updateModel("subsidiaryAddress", e.target.value)}
                                                id="serviceprovideredit_subsidiaryaddress"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                t={t}
                                                model={mainModel.subsidiaryCity}
                                                onChange={e => updateModel("subsidiaryCity", e.target.value)}
                                                id="serviceprovideredit_subsidiarycity"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PhoneNumberInputField
                                                model={mainModel.subsidiaryTelephone}
                                                onChange={(e: any) => updateModel("subsidiaryTelephone", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SingleSelectComponent2
                                                t={t}
                                                model={mainModel.salutation}
                                                listFromDb={Salutations}
                                                onChange={(e) => updateModel('salutation', e.target.value as number)}
                                                variant={'standard'} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                t={t}
                                                model={mainModel.firstName}
                                                onChange={e => updateModel("firstName", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                t={t}
                                                model={mainModel.lastName}
                                                onChange={e => updateModel("lastName", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                t={t}
                                                model={mainModel.email}
                                                onChange={e => updateModel("email", e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={3} direction="column">
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                t={t}
                                                model={mainModel.leakageDetectionCosts}
                                                onChange={e => updateModel("leakageDetectionCosts", e.target.value)}
                                                id="serviceprovideredit_leakagedetectioncosts"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                t={t}
                                                model={mainModel.repairLabourCosts}
                                                onChange={e => updateModel("repairLabourCosts", e.target.value)}
                                                id="serviceprovideredit_repairlabourcosts"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                t={t}
                                                model={mainModel.travelTimeCostsPerHour}
                                                onChange={e => updateModel("travelTimeCostsPerHour", e.target.value)}
                                                id="serviceprovideredit_traveltimecostsperhour"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                t={t}
                                                model={mainModel.sparePartsCosts}
                                                onChange={e => updateModel("sparePartsCosts", e.target.value)}
                                                id="serviceprovideredit_sparepartscosts"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <NumberInputFieldComponent
                                                t={t}
                                                model={mainModel.costsPerDistanceUnit}
                                                onChange={e => updateModel("costsPerDistanceUnit", InputRestrictions.Decimal(e.target.value))}
                                                id="serviceprovideredit_costsperdistanceunit"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                t={t}
                                                model={mainModel.lumpSum}
                                                onChange={e => updateModel("lumpSum", e.target.value)}
                                                id="serviceprovideredit_lumpsum"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3} direction="row">
                                <Grid item xs={6} md={6}>
                                    <HeadingComponent value={t("PaybackTime")} size="h6" />
                                </Grid>
                                <Grid item xs={6} md={6}></Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={3} direction="column">
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                t={t}
                                                model={mainModel.equipmentServiceLife}
                                                onChange={e => updateModel("equipmentServiceLife", e.target.value)}
                                                id="serviceprovideredit_equipmentservicelife"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={3} direction="column">
                                        <Grid item xs={12}>
                                            <TextFieldComponent
                                                t={t}
                                                model={mainModel.taxOnProfit}
                                                onChange={e => updateModel("taxOnProfit", e.target.value)}
                                                id="serviceprovideredit_taxonprofit"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3} direction="row">
                                <Grid item xs={12}>
                                    <HeadingComponent
                                        value={t("RestrictedSettings")}
                                        size="h6"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <NumberInputFieldComponent
                                        t={t}
                                        model={mainModel.settingsLeakageDetectedDayFlow}
                                        onChange={e => updateModel("settingsLeakageDetectedDayFlow", InputRestrictions.Decimal(e.target.value))}
                                        id="serviceprovideredit_settingsleakagedetecteddayflow"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <NumberInputFieldComponent
                                        t={t}
                                        model={mainModel.settingsLeakageDetectedDayPerTechnician}
                                        onChange={e => updateModel("settingsLeakageDetectedDayPerTechnician", InputRestrictions.Decimal(e.target.value))}
                                        id="serviceprovideredit_settingsleakagedetecteddaypertechnician"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <NumberInputFieldComponent
                                        t={t}
                                        model={mainModel.settingsRepairTimePerLeakage}
                                        onChange={e => updateModel("settingsRepairTimePerLeakage", InputRestrictions.Decimal(e.target.value))}
                                        id="serviceprovideredit_settingsrepairtimeperleakage"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <NumberInputFieldComponent
                                        t={t}
                                        model={mainModel.settingsWorkingHoursPerDayPerDetection}
                                        onChange={e => updateModel("settingsWorkingHoursPerDayPerDetection", InputRestrictions.Decimal(e.target.value))}
                                        id="serviceprovideredit_settingsworkinghoursperdayperdetection"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <NumberInputFieldComponent
                                        t={t}
                                        model={mainModel.settingsSavings1barReduction}
                                        onChange={e => updateModel("settingsSavings1barReduction", InputRestrictions.Decimal(e.target.value))}
                                        id="serviceprovideredit_settingssavings1barreduction"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid item xs={12} md={6}>
                                <HeadingComponent
                                    value={t('AddEditExecutorUsers')}
                                    size="h6"
                                />
                            </Grid>
                            <TableHeadingContent
                                NotShowAddDelete={!(HasUserRole([Roles.SuperAdmin], user) || isExecutorAdmin)}
                                clickDelete={deleteExecutorUser}
                                clickAdd={() => handleEditExecutorUser(0)}
                                defaultData={executorsModel}
                                filterData={filterData}
                                onFilterFunction={filterFunction}
                                filterModel={FilterModel.AddEditExecutorUsers}
                                tableId={"AddEditExecutorUsers"}
                            />
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>{t("FirstName")}</TableCell>
                                        <TableCell>{t("LastName")}</TableCell>
                                        <TableCell>{t("Email")}</TableCell>
                                        <TableCell>{t("Role")}</TableCell>
                                        <TableCell>{t("IsDefaultExecutor")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {executorsModel.length > 0 &&
                                        executorsModel
                                            .slice(page * rowsPerPage, rowsPerPage + page * rowsPerPage)
                                            .map((item, index) => (
                                                <TableRow key={index + 1}>
                                                    <TableCell>
                                                        {(HasUserRole([Roles.SuperAdmin], user) || isExecutorAdmin) &&
                                                            <SimpleCheckbox
                                                                id={"CAG-checkbox" + index}
                                                                key={Math.random()}
                                                                defaultChecked={isCheckedForDeletion(
                                                                    page * rowsPerPage + index
                                                                )}
                                                                color="default"
                                                                onChange={() => handleCheck(item.id, index)}
                                                            />
                                                        }
                                                    </TableCell>
                                                    <TableCell id={"td_firstName" + index}>
                                                        {item.firstName}
                                                    </TableCell>
                                                    <TableCell id={"td_lastName" + index}>
                                                        {item.lastName}
                                                    </TableCell>
                                                    <TableCell id={"td_email" + index}>
                                                        {item.email}
                                                    </TableCell>
                                                    <TableCell id={"td_role" + index}>
                                                        {t(item.role?.value ?? "")}
                                                    </TableCell>
                                                    <TableCell id={"td_isDefaultExecutor" + index}>
                                                        {item.isDefaultExecutor ? <DoneIcon /> : <CloseIcon />}
                                                    </TableCell>
                                                    <TableCell>
                                                        {(HasUserRole([Roles.SuperAdmin], user) || isExecutorAdmin) &&
                                                            <IconButton
                                                                id={"btn_CAG-edit" + index}
                                                                onClick={() => {
                                                                    handleEditExecutorUser(item.id);
                                                                }}
                                                                size="large"
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 20, 25]}
                            component="div"
                            count={executorsModel && executorsModel.length > 0 ? executorsModel.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                ) : (
                    <Grid container spacing={3} direction="column">
                        <Grid item xs={12}>
                            <HeadingComponent
                                value={t("ServiceExecutorInformation")}
                                size="h6"
                            />
                            <TextFieldComponent
                                t={t}
                                model={mainModel.subsidiaryFestoName}
                                onChange={e => updateModel("subsidiaryFestoName", e.target.value)}
                                id="serviceprovideredit_subsidiaryfestoname"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteDropdown
                                model={mainModel.countryID}
                                options={countryList}
                                optionName="countryName"
                                optionKey="countryCode"
                                onChange={(e: { countryCode: any; }) => updateModel("countryID", e?.countryCode)}
                                id="serviceprovideredit_countryID"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                t={t}
                                model={mainModel.subsidiaryAddress}
                                onChange={e => updateModel("subsidiaryAddress", e.target.value)}
                                id="serviceprovideredit_subsidiaryaddress"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                t={t}
                                model={mainModel.subsidiaryCity}
                                onChange={e => updateModel("subsidiaryCity", e.target.value)}
                                id="serviceprovideredit_subsidiarycity"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneNumberInputField
                                model={mainModel.subsidiaryTelephone}
                                onChange={(e: any) => updateModel("subsidiaryTelephone", e)}
                                id="serviceprovideredit_subsidiarytelephone"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SingleSelectComponent2
                                t={t}
                                model={mainModel.salutation}
                                listFromDb={Salutations}
                                onChange={(e) => updateModel('salutation', e.target.value as number)}
                                variant={'standard'} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                t={t}
                                model={mainModel.firstName}
                                onChange={e => updateModel("firstName", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                t={t}
                                model={mainModel.lastName}
                                onChange={e => updateModel("lastName", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent
                                t={t}
                                model={mainModel.email}
                                onChange={e => updateModel("email", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid container direction="row" justifyContent="flex-end" style={{ margin: "50px 0 30px" }}>
                    <Grid item>
                        <ButtonComponent
                            value={t("Cancel")}
                            color="secondary"
                            onChange={clickCancel}
                            id="serviceprovideredit_cancel"
                        ></ButtonComponent>
                    </Grid>
                    <Grid item>
                        <ButtonComponent
                            value={t("Save")}
                            color="primary"
                            onChange={saveForm}
                            id="serviceprovideredit_save"
                        ></ButtonComponent>
                    </Grid>
                </Grid>
            </div>
        </div >
        :
        <AccountLicenses
            loadExecutor={loadExecutor}
            mainModel={executorData}
            loadExistingData={loadExistingData}
            setIsMainView={setIsMainView}
        />;
}
