// @ts-expect-error not converted yet
import { checkIsInteger, checkIsNotNullOrWhitespace, checkIsDecimal, checkIsAnythingSelectedObject, checkIsDateInPast, checkIsDateInPastUTC } from "../../../helper/Validator";
import * as Fmt from '../../../helper/Formatters';
import { UserStore } from '../../../store';
import { Translate, ModelParam, ModelFactory } from "../../Types/Types";
import { IUser } from "../../../store/UserStore";
import { nowUTC } from "../../../helper/DateHelper";

let user: IUser = {};
UserStore.user.subscribe(usr => {
    user = usr;
})

export interface ILeakageModelFactory {
    id: ModelParam<number>;
    guid: ModelParam<string | null>;
    measurementId: ModelParam<string>;

    detectedDate: ModelParam<Date>
    detectedBy: ModelParam<string>

    leakageRepaired: ModelParam<boolean>;
    repairedBy: ModelParam<string>;
    repairDate: ModelParam<Date | null>;

    leakageComment: ModelParam<string>;
    repairComment: ModelParam<string>;

    building: ModelParam<string>;
    department: ModelParam<string>;
    machine: ModelParam<string>;

    leackageNumber: ModelParam<string>;
    leakageRepairTimeRepairedInput: ModelParam<string>;
    leakageOptimization: ModelParam<boolean>;
    leakageInputDevice: ModelParam<string>;
    leakageInputValue: ModelParam<string>;
    leakageFlowConverted: ModelParam<number>;
    leakageAction: ModelParam<string>;
    leakageRepairTimeEstimated: ModelParam<string>;
    leakageRepearableProdTime: ModelParam<boolean>;
}

export interface ILeakageCalculationsModelFactory {
    costPerYear: ModelParam<string>;
    leakageClasification: ModelParam<string>;
}

export const LeakageModelFactory = (t: Translate): ModelFactory<ILeakageModelFactory> => (
    {
        id: { default: 0, headerName: t("MeasurementId"), idkey: "LDAREDIT-Id", isValid: true, label: t("Id"), step: 0, type: -1, validationError: "", validators: [], value: 0 },
        guid: { idkey: "LDAREDIT-guid", isValid: true, label: "guid", validationError: "", validators: [], value: null },
        building: { default: "", headerName: t('Building'), idkey: "LDAREDIT-building", isValid: true, label: t("Building"), validationError: t("notfilledErrorMessage"), validators: [checkIsNotNullOrWhitespace], value: "" },
        department: { default: "", headerName: t('Department'), idkey: "LDAREDIT-department", isValid: true, label: t("Department"), validationError: t("notfilledErrorMessage"), validators: [checkIsNotNullOrWhitespace], value: "" },
        detectedBy: { default: "", headerName: t('DetectedBy'), idkey: "LDAREDIT-detectedBy", isValid: true, label: t("DetectedBy"), validationError: t("DetectedByError"), validators: [checkIsNotNullOrWhitespace], value: user.firstName + " " + user.lastName },
        detectedDate: { default: null, headerName: t('EntryDate'), idkey: "LDAREDIT-detectedDate", isValid: true, label: t("EntryDate"), validationError: t("DetectedDateError"), validators: [checkIsDateInPastUTC], value: nowUTC() },
        leackageNumber: { default: "", headerName: t('LeakageNr'), idkey: "LDAREDIT-leackageNumber", isValid: true, label: t("LeackageNumber"), validationError: t("ValidNumberError"), validators: [], value: "" },
        leakageAction: { default: "", headerName: t('LeakageAction'), idkey: "LDAREDIT-leakageAction", isValid: true, label: t("LeakageAction"), validationError: t("notfilledErrorMessage"), validators: [], value: "" },
        leakageComment: { default: "", headerName: t('Comment'), idkey: "LDAREDIT-leakageComment", isValid: true, label: t("LeakageComment"), validationError: t("notfilledErrorMessage"), validators: [], value: "" },
        leakageFlowConverted: { default: "", headerName: t("AirFlow") + Fmt.getUnitInBracket("volumeFlowRate", t), idkey: "LDAREDIT-leakageFlowConverted", isValid: true, label: t("LeakageFlowConverted") + Fmt.getUnitInBracket("volumeFlowRate", t), validationError: t("notfilledErrorMessage"), validators: [], value: 0 },
        leakageInputDevice: { default: "", headerName: t('LeakageInputDevice'), idkey: "LDAREDIT-leakageInputDevice", isValid: true, label: t("LeakageInputDevice"), validationError: t("ValidSelectError"), validators: [isLeakageInputDeviceFilled], value: "" },
        leakageInputValue: { default: "", headerName: t('LeakageInputValue'), idkey: "LDAREDIT-leakageInputValue", isValid: true, label: t("LeakageInputValue"), validationError: t("ValidNumberError"), validators: [isLeakageInputValueFilled], value: "" },
        leakageOptimization: { default: false, headerName: t('LeakageOptimization'), idkey: "LDAREDIT-OptimizationInsteadOfLeakage", isValid: true, label: t("OptimizationInsteadOfLeakage"), validationError: t("notfilledErrorMessage"), validators: [], value: false },
        leakageRepaired: { default: false, headerName: t('LeakageRepaired'), idkey: "LDAREDIT-leakageRepaired", isValid: true, label: t("LeakageRepaired"), validationError: t("ValidNumberError"), validators: [checkIsNotNullOrWhitespace], value: false },
        
        leakageRepairTimeEstimated: { default: "", headerName: t("EstimatedRepairTime") + Fmt.addBrackets("min", t), idkey: "LDAREDIT-leakageRepairTimeEstimated", isValid: true, label: t("EstimatedRepairTime"), validationError: t("notfilledErrorMessage"), validators: [], value: "" },
        leakageRepairTimeRepairedInput: { default: "", headerName: t('LeakageRepairTimeRepairedInput'), idkey: "LDAREDIT-leakageRepairTimeRepairedInput", isValid: true, label: t("LeakageRepairTimeRepairedInput"), validationError: t("ValidNumberError"), validators: [isLeakageRepairTimeRepairedInputFilled], value: "" },
        
        leakageRepearableProdTime: { default: false, headerName: t('LeakageRepearableProdTime'), idkey: "LDAREDIT-leakageRepearableProdTime", isValid: true, label: t("LeakageRepearableProdTime"), validationError: t("notfilledErrorMessage"), validators: [], value: false },
        machine: { default: "", headerName: t('Machine'), idkey: "LDAREDIT-machine", isValid: true, label: t("Machine"), validationError: t("notfilledErrorMessage"), validators: [checkIsNotNullOrWhitespace], value: "" },
        measurementId: { default: "", headerName: t('UniqueId'), idkey: "LDAREDIT-measurementId", isValid: true, label: t("MeasurementId"), validationError: t("MeasurementIdError"), validators: [checkIsInteger], value: "" },
        repairComment: { headerName: t(''), idkey: "LDAREDIT-RepairComment", isValid: true, label: t("RepairComment"), validationError: "", validators: [], value: "" },
        repairDate: { default: null, headerName: t('RepairDate'), idkey: "LDAREDIT-repairDate", isValid: true, label: t("RepairDate"), validationError: t("ValidNumberError"), validators: [isRepaireDateFilled], value: null },
        repairedBy: { default: "", headerName: t('RepairedBy'), idkey: "LDAREDIT-repairedBy", isValid: true, label: t("RepairedBy"), validationError: t("ValidNumberError"), validators: [isRepairedByFilled], value: "" }
    }
)

export const LeakageCalculationsModelFactory = (t: Translate): ModelFactory<ILeakageCalculationsModelFactory> => ({
    costPerYear: { label: "", idkey: "LDAREDIT-costPerYear", value: "", default: "", isValid: true, validationError: "", validators: [], headerName: t('CostPerYear') + Fmt.getCurrency() },
    leakageClasification: { label: "", idkey: "LDAREDIT-leakageClasification", value: "", default: "", isValid: true, validationError: "", validators: [], headerName: t('LeakageClasification') },
})

const isRepairedByFilled = (value: string, model?: ILeakageModelFactory | void) => model?.leakageRepaired.value === false || checkIsNotNullOrWhitespace(value);
const isRepaireDateFilled = (value: Date | null, model?: ILeakageModelFactory | void) => model?.leakageRepaired.value === false || checkIsDateInPast(value);
const isLeakageRepairTimeRepairedInputFilled = (value: string, model?: ILeakageModelFactory | void) => model?.leakageRepaired.value === false || checkIsInteger(value);
const isLeakageInputDeviceFilled = (value: string, model?: ILeakageModelFactory | void) => model?.leakageOptimization.value === true || checkIsAnythingSelectedObject(value);
const isLeakageInputValueFilled = (value: string, model?: ILeakageModelFactory | void) => model?.leakageOptimization.value === true || checkIsDecimal(value);