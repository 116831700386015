// @ts-expect-error not converted yet
import { checkIsInteger, checkIsNotNullOrWhitespace, checkIsAnythingSelectedObject } from '../../helper/Validator';
import { IDropdownItem, ModelFactory, ModelParam, Translate } from '../Types/Types';

export interface IReplacementFactory {
    id: ModelParam<number>;
    no: ModelParam<number>;
    guid?: ModelParam<string>;
    partNumber?: ModelParam<string>;
    typeCode?: ModelParam<string>;
    component?: ModelParam<IDropdownItem>;
    manufacturer?: ModelParam<string>;
    replaceWithDifferent: ModelParam<boolean>;
    newPartNumber?: ModelParam<string>;
    newTypeCode?: ModelParam<string>;
    newManufacturer?: ModelParam<string>;
    quantity: ModelParam<number>;
    orderSparePart: ModelParam<boolean>;
}


export const ReplacementPartModel = (t: Translate): ModelFactory<IReplacementFactory> => ({
    id: { value: 0, isValid: true, validationError: "", validators: [], disabled: true, idkey: 'RPART-Id', label: t("UniquePartCode"), headerName: t('UniquePartCode'), },
    no: { idkey: "RPART-No", value: 0, isValid: true, validationError: "", validators: [], disabled: true, headerName: t('Nr'), label: t('Nr') },
    partNumber: { label: t("PartNumber"), idkey: "RPART-PartNumber", value: "", isValid: true, validationError: "", validators: [] },
    component: { label: t("Component"), idkey: "RPART-Component", value: "", isValid: true, validationError: "", validators: [checkIsAnythingSelectedObject] },
    manufacturer: { label: t("Manufacturer"), idkey: "RPART-Manufacturer", value: "", isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace] },
    typeCode: { label: t("TypeCode"), idkey: "RPART-TypeCode", value: "", isValid: true, validationError: "", validators: [] },
    newPartNumber: { label: t("NewPartNumber"), idkey: "RPART-NewPartNumber", value: "", isValid: true, validationError: "", validators: [], headerName: t('Replacement') },
    newTypeCode: { label: t("NewTypeCode"), idkey: "RPART-NewTypeCode", value: "", isValid: true, validationError: "", validators: [] },
    newManufacturer: { label: t("NewManufacturer"), idkey: "RPART-NewManufacturer", value: "", isValid: true, validationError: "", validators: [] },
    replaceWithDifferent: { label: t("ReplaceWithDifferentPart"), idkey: "RPART-ReplaceWithDifferent", value: false, isValid: true, validationError: "", validators: [] },
    quantity: { label: t("Quantity"), idkey: "RPART-Quantity", value: 1, isValid: true, validationError: "", validators: [checkIsInteger] },
    orderSparePart: { label: t("OrderSparePart"), idkey: "RPART-OrderSparePart", value: true, isValid: true, validationError: "", validators: [] },
})